import React from 'react';
import clsx from "clsx";

import classes from "./CardWrapper.module.scss";

export const CardWrapper = ({classes, classNameWrapper, header, children}) => {
    return (
        <section className={clsx(classNameWrapper, classes.card_wrapper)}>
            <header className={classes.header}>{header}</header>
            <main className={classes.main}>{children}</main>
        </section>
    );
}

CardWrapper.defaultProps = {
	classes,
    classNameWrapper: "",
	header: "",
	children: "",
};
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
	GoogleMap,
	MarkerClusterer,
	Marker,
	Autocomplete,
	DirectionsRenderer,
	InfoWindow,
} from "@react-google-maps/api";
import { Icon } from "@iconify/react";

import {
	filteredAndSortedOffices,
	getOfficeDirection,
	officeLoadingSelector,
	loadingFilteredOfficesSelector,
	officeDirectionSelector,
	officesWithDistanceSelector,
	officesSelector,
} from "../../store/offices.js";

import { keys } from "../../locales/localeskeys";

import Button from "../ui/Button/Button";
import Loading from "../ui/loading/Loading";
import TextInput from "../ui/Input/TextInput/TextInput";

import classes from "./Offices.module.scss";
import CustomNavLink from "../ui/customNavLink/CustomNavLink";

const Offices = ({ classes }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const autocompleteRef = useRef(null);

	const [searchOffice, setSearchOffice] = useState("");
	const [isValid, setIsValid] = useState(true);
	const [error, setError] = useState("");
	const [selected, setSelected] = useState(0);
	const [activeOffice, setActiveOffice] = useState(0);
	const [selectedOffice, setSelectedOffice] = useState(null);
	const [zoomInOffice, setZoomInOffice] = useState(null);
	const [lockSearch, setLockSearch] = useState(false);
	const [mapState, setMapState] = useState({
		center: {
			lat: 45.155499,
			lng: -102.494932,
		},
		zoom: 4,
	});

	const officesWithDistance = useSelector(officesWithDistanceSelector);
	const offices = useSelector(officesSelector);
	const isLoading = useSelector(officeLoadingSelector);
	const isLoadingOfficesSearch = useSelector(loadingFilteredOfficesSelector);
	let officeDirection = useSelector(officeDirectionSelector);

	useEffect(() => {
		if (searchOffice !== "" && officesWithDistance[selected]?.distance !== undefined) {
			dispatch(getOfficeDirection({ origin: searchOffice, destination: officesWithDistance[selected]?.address }));
		} else {
			officeDirection = undefined;
		}
	}, [searchOffice, officesWithDistance[selected]?.distance]);

	useEffect(() => {
		if (!searchOffice || searchOffice === "") {
			setIsValid(true);
			setLockSearch(false);
			setError("");
		} else if (!isValid && searchOffice && searchOffice !== "") {
			setIsValid(true);
			setError("");
		}
	}, [searchOffice, isValid]);

	useEffect(() => {
		if (offices.length > 0 && searchOffice === "") {
			dispatch(filteredAndSortedOffices({ searchOffice: searchOffice }));
			dispatch(getOfficeDirection({ origin: searchOffice, destination: officesWithDistance[selected]?.address }));
			setSelected(0);
		}
	}, [offices, searchOffice]);

	useEffect(() => {
		if (zoomInOffice === null) {
			setMapState({
				center: {
					lat: 45.51681,
					lng: -73.45519,
				},
				zoom: 4,
			});
		} else {
			setMapState({
				center: {
					lat: parseFloat(zoomInOffice?.lat),
					lng: parseFloat(zoomInOffice?.lng),
				},
				zoom: 15,
			});
		}
	}, [zoomInOffice]);

	const searchSubmit = (event) => {
		event.preventDefault();

		if (!lockSearch) {
			const autocompleteValue = autocompleteRef.current.getPlace()?.formatted_address;
			if (autocompleteValue) {
				setSearchOffice(autocompleteValue);
			}

			const validationError = validateAddress(autocompleteValue);

			if (validationError) {
				setIsValid(false);
				setError(validationError);
			} else {
				setIsValid(true);
				setLockSearch(true);
				setError("");
				dispatch(filteredAndSortedOffices({ searchOffice: autocompleteValue }));
			}
		}
	};

	const searchChangeHandler = (event) => {
		setSearchOffice(event.target.value ?? "");
		setSelected(0);
		setZoomInOffice(null);
		setLockSearch(false);
	};

	const validateAddress = (address) => {
		if (!address || address === "") {
			return "Please enter a valid address";
		}
		return "";
	};
	const activeHandler = (office, index) => {
		if (office && office?.distance) {
			setZoomInOffice(null);
		}
		if (!office?.distance) {
			setZoomInOffice(officesWithDistance[index]);
			if (selected === index && zoomInOffice !== null) {
				setZoomInOffice(null);
			}
		}
		setSelected(index);
		if (index === activeOffice) setActiveOffice(0);
		else setActiveOffice(index);
	};

	const formatTime = (timeString) => {
		const [hours, minutes, _] = timeString.split(":");
		const formattedHours = parseInt(hours, 10) % 12 || 12;
		const period = parseInt(hours, 10) < 12 ? "a.m" : "p.m";

		return `${formattedHours}:${minutes} ${period}`;
	};

	const handleGoogleMapsButtonClick = (office) => {
		const origin = encodeURIComponent(searchOffice);
		const destination = encodeURIComponent(office?.address);
		const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${origin}&destination=${destination}`;
		window.open(googleMapsUrl, "_blank");
	};
	const handleClearClick = () => {
		setSearchOffice("");
	};

	return (
		<Fragment>
			<div className={classes.header}>
				<h1 className={classes.title}>{t(keys.FIND_STORE.OFFICE.TITLE)}</h1>
				<form onSubmit={searchSubmit}>
					<div className={classes.searchLayout}>
						<Autocomplete
							className={classes.searchInput}
							onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
							onPlaceChanged={() => {
								const place = autocompleteRef.current.getPlace();
								setSearchOffice(place.formatted_address);
							}}
						>
							<TextInput
								className={classes.searchInput}
								inputclass={classes.inputclass}
								searchClass={classes.searchClass}
								placeholder={t(keys.FIND_STORE.META.PLACEHOLDER)}
								onChange={searchChangeHandler}
								value={searchOffice}
								search={searchSubmit}
								isValid={isValid}
								error={error}
							/>
						</Autocomplete>
					</div>
					<div className={classes.buttons}>
						<Button type="submit" value="submit" size="big" className={classes.applyBtn}>
							{t(keys.FIND_STORE.META.FIND)}
						</Button>
						<Button size="big" className={classes.clearBtn} onClick={handleClearClick}>
							{t(keys.FIND_STORE.META.CLEAR)}
						</Button>
					</div>
				</form>
			</div>
			<div className={classes.container}>
				{(isLoading || isLoadingOfficesSearch) && <Loading type={"bounce"} />}
				{!isLoading && !isLoadingOfficesSearch && (
					<Fragment>
						<div className={classes.map}>
							{officesWithDistance && officesWithDistance.length > 0 && (
								<GoogleMap
									center={mapState.center}
									zoom={mapState.zoom}
									mapContainerStyle={{ width: "100%", height: "100%" }}
									options={{ streetViewControl: false }}
								>
									<MarkerClusterer>
										{(clusterer) =>
											officesWithDistance.map((office) => (
												<Marker
													key={office.id}
													position={{
														lat: parseFloat(office.lat),
														lng: parseFloat(office.lng),
													}}
													clusterer={clusterer}
													onClick={() => setSelectedOffice(office)}
													icon={{
														url: "/static/favicon/Google-Map-Icon.png",
														scaledSize:
															window.google && window.google.maps
																? new window.google.maps.Size(25, 35)
																: undefined,
													}}
												/>
											))
										}
									</MarkerClusterer>

									{officeDirection !== undefined && (
										<DirectionsRenderer
											directions={officeDirection}
											options={{ suppressMarkers: true }}
										/>
									)}
									{officeDirection !== undefined &&
										officeDirection?.routes[0]?.legs[0]?.start_location && (
											<Marker
												position={officeDirection?.routes[0]?.legs[0]?.start_location}
												label=""
											/>
										)}
									{selectedOffice?.address && (
										<InfoWindow
											position={{
												lat: parseFloat(selectedOffice.lat),
												lng: parseFloat(selectedOffice.lng),
											}}
											onCloseClick={() => setSelectedOffice(null)}
										>
											<div className={classes.infoWindow}>
												<div className={classes.title}>{selectedOffice.name}</div>
												{selectedOffice.address && (
													<p>
														{t(keys.CONTACT.ADDRESS)} {selectedOffice.address}{" "}
													</p>
												)}
												<p>
													{t(keys.CONTACT.PHONE)}
													{selectedOffice.phone && (
														<a href={`tel:${selectedOffice.phone}`}>
															{selectedOffice.phone}
														</a>
													)}
												</p>
												<Button
													onClick={() => handleGoogleMapsButtonClick(selectedOffice)}
													className={classes.Website}
												>
													{t(keys.FIND_STORE.META.GET_DIRECTION)}
												</Button>
											</div>
										</InfoWindow>
									)}
								</GoogleMap>
							)}
						</div>
						<div className={classes.list}>
							{officesWithDistance.map((office, index) => (
								<div
									className={classes.address}
									style={{
										border:
											(officeDirection || zoomInOffice) && selected === index
												? ".2rem solid #007AFF"
												: "",
									}}
									key={office.id}
								>
									<div className={`${classes.title} ${classes[index === selected ? "active" : ""]}`}>
										<div
											onClick={() => activeHandler(office, index)}
											className={classes.description}
										>
											{office.name}
										</div>
										<Icon
											onClick={() => activeHandler(office, index)}
											className={`${classes.arrow} ${
												classes[index === selected ? "active" : ""]
											}`}
											icon="dashicons:arrow-down-alt2"
										/>
									</div>
									<div
										className={`${classes.details} ${classes[index === selected ? "active" : ""]}`}
										style={{ height: index === selected ? "100%" : "0px" }}
									>
										<div className={classes.top}>
											<div className={classes.left}>
												<div className={classes.information}>
													<p className={classes.label}>{t(keys.CONTACT.PHONE)}</p>
													<p className={classes.value}>{office.phone}</p>
												</div>
												<div className={classes.information}>
													<p className={classes.label}>{t(keys.CONTACT.FREE)}</p>
													<p className={classes.value}>{office.toll}</p>
												</div>
												<div className={classes.information}>
													<p className={classes.label}>{t(keys.CONTACT.EMAIL)}</p>
													<p className={`${classes.value} ${classes.highlight}`}>
														<a
															href={`mailto:${office.email}`}
															className={classes.highlight}
														>
															{office.email}
														</a>
													</p>
												</div>

												{office.fax && (
													<div className={classes.information}>
														<p className={classes.label}>{t(keys.CONTACT.FAX)}</p>
														<p className={classes.value}>{office.fax}</p>
													</div>
												)}
											</div>
											<div className={classes.right}>
												<p className={classes.label}>{t(keys.CONTACT.ADDRESS)}</p>
												{office.address && (
													<p
														className={classes.links}
														onClick={() => handleGoogleMapsButtonClick(office)}
													>
														{" "}
														{office.address}{" "}
													</p>
												)}
											</div>
										</div>
										<div className={classes.bottom}>
											<div className={classes.information}>
												<p className={classes.label}>{t(keys.CONTACT.OPENING_HOURS)}</p>
												<p className={classes.value}>
													{office.open_time ? formatTime(office.open_time) : "08:00"} -{" "}
													{office.close_time ? formatTime(office.close_time) : "17:00"}
												</p>
											</div>
										</div>
									</div>
								</div>
							))}
						</div>
					</Fragment>
				)}
			</div>
		</Fragment>
	);
};

Offices.defaultProps = {
	classes,
};

export default Offices;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button as ButtonMui } from "@material-ui/core";
import { Icon } from "@iconify/react";
import clsx from "clsx";

const useStyles = makeStyles({
	root: {
		borderRadius: 0,
		boxShadow: "none",
		textTransform: "none",
		fontSize: 16,
		minWidth: 157,
		border: "1px solid",
		lineHeight: 1.5,
		textWrap: "nowrap",
		"&:focus": {
			boxShadow: "none",
		},
	},
	iconBorderLess: {
		border: "none",
		minWidth: "fit-content",
		padding: "0px",
	},
	default: {
		backgroundColor: "#007AFF",
		color: "#FFFFFF",
		borderColor: "#085CC5",
		"&:hover, &:active": {
			backgroundColor: "#085CC5",
			borderColor: "#0D509B",
		},
	},
	outlined: {
		backgroundColor: "#FFFFFF",
		color: "#007AFF",
		borderColor: "#007AFF",
		"&:hover, &:active": {
			backgroundColor: "#FFFFFF",
			color: "#085CC5",
			borderColor: "#0D509B",
		},
	},
	greyOutlined: {
		backgroundColor: "#F4F4F4",
		color: "#000000",
		borderColor: "#007AFF",
		padding: ".95rem",
		"&:hover, &:active": {
			backgroundColor: "#F4F4F4",
			color: "#085CC5",
			borderColor: "#0D509B",
		},
	},
	negative: {
		backgroundColor: "#FFFFFF",
		color: "#333333",
		borderColor: "#999999",
		"&:hover, &:active": {
			backgroundColor: "#F4F4F4",
			borderColor: "#F4F4F4",
		},
	},
	danger: {
		backgroundColor: "#FF4D4D",
		color: "#FFCCCC",
		borderColor: "#CC3E3E",
		"&:hover, &:active": {
			backgroundColor: "#CC3E3E",
			borderColor: "#CC3E3E",
		},
	},
	dangerOutlined: {
		backgroundColor: "#FFFFFF",
		color: "#FF4D4D",
		borderColor: "#CC3E3E",
		"&:hover, &:active": {
			backgroundColor: "#FFFFFF",
			borderColor: "#CC3E3E",
		},
	},
	spinner: {
		display: "inline-block",
		animation: "spin 1s linear infinite",
	},
	"@keyframes spin": {
		from: {
			transform: "rotate(0deg)",
		},
		to: {
			transform: "rotate(360deg)",
		},
	},
});

export const Button = ({ children, color, icon, onClick, disabled, type, loading, iconBorderLess, style }) => {
	const classes = useStyles();

	return (
		<ButtonMui
			variant="contained"
			className={clsx(
				classes.root,
				iconBorderLess ? classes.iconBorderLess : "",
				{
					[classes.outlined]: color === "outlined",
					[classes.negative]: color === "negative",
					[classes.danger]: color === "danger",
					[classes.dangerOutlined]: color === "danger-outlined",
					[classes.greyOutlined]: color === "greyOutlined",
					[classes.default]: !color || color === "default",
				},
				style
			)}
			startIcon={icon && !loading && <Icon icon={icon} />}
			onClick={onClick}
			disabled={disabled}
			type={type}
		>
			{loading ? <Icon icon="ei:spinner-3" width="24" height="24" className={classes.spinner} /> : children}
		</ButtonMui>
	);
};

Button.defaultProps = {
	color: "default",
	icon: null,
	onClick: () => {},
	disabled: false,
	type: null,
	loading: false,
};

import React, { forwardRef, Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
	GoogleMap,
	MarkerClusterer,
	Marker,
	Autocomplete,
	DirectionsRenderer,
	InfoWindow,
} from "@react-google-maps/api";
import { Icon } from "@iconify/react";
import {
	filteredAndSortedStores,
	getStoreDirection,
	officeLoadingSelector,
	loadingFilteredStoresSelector,
	storeDirectionSelector,
	storesSelector,
	storesWithDistanceSelector,
} from "../../../store/offices.js";

import { keys } from "../../../locales/localeskeys";
import useElementSize from "../../../hooks/useElementSize";

import Loading from "../../ui/loading/Loading";
import Button from "../../ui/Button/Button";
import CustomNavLink from "../../ui/customNavLink/CustomNavLink";
import TextInput from "../../ui/Input/TextInput/TextInput";

import classes from "./Stores.module.scss";
import { IconButton, InputAdornment, TextField } from "@material-ui/core";

const Stores = forwardRef(({ classes, ...props }, ref) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const stores = useSelector(storesSelector);
	const storesWithDistance = useSelector(storesWithDistanceSelector);
	const isLoading = useSelector(officeLoadingSelector);
	const isLoadingStoresSearch = useSelector(loadingFilteredStoresSelector);
	let storeDirection = useSelector(storeDirectionSelector);
	const [gridRef, { width: gridWidth }] = useElementSize();

	const autocompleteRef = useRef(null);

	const [searchStore, setSearchStore] = useState("");
	const [isValid, setIsValid] = useState(true);
	const [zoomInStore, setZoomInStore] = useState(null);
	const [error, setError] = useState("");
	const [currentPage, setCurrentPage] = useState(1);
	const [viewMode, setViewMode] = useState("list");
	const [selected, setSelected] = useState(0);
	const [selectedStore, setSelectedStore] = useState(null);
	const [searchTerm, setSearchTerm] = useState("");
	const [lockSearch, setLockSearch] = useState(false);

	const [mapState, setMapState] = useState({
		center: {
			lat: 45.51681,
			lng: -73.45519,
		},
		zoom: 4,
	});

	const itemsPerPage = 20;

	const searchSubmit = (event) => {
		event.preventDefault();

		if (!lockSearch) {
			const autocompleteValue = autocompleteRef.current.getPlace()?.formatted_address;
			if (autocompleteValue) {
				setSearchStore(autocompleteValue);
			}
			const validationError = validateAddress(autocompleteValue);
			if (validationError) {
				setIsValid(false);
				setError(validationError);
			} else {
				setIsValid(true);
				setLockSearch(true);
				setError("");
				dispatch(filteredAndSortedStores({ searchStore: searchStore }));
			}
		}
	};

	const searchChangeHandler = (event) => {
		setSearchStore(event.target.value ?? "");
		setSelected(0);
		setZoomInStore(null);
		setLockSearch(false);
	};

	const validateAddress = (address) => {
		if (!address || address === "") {
			return "Please enter a valid address";
		}
		return "";
	};

	const handleNext = () => {
		setCurrentPage((prevPage) => Math.min(prevPage + 1, Math.ceil(storesWithDistance?.length / itemsPerPage)));
		setSelected(0);
		setZoomInStore(null);
	};

	const handlePrev = () => {
		setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
		setSelected(0);
		setZoomInStore(null);
	};

	const filteredStores = storesWithDistance?.filter((store) =>
		store.name.toLowerCase().includes(searchTerm.toLowerCase())
	);

	const paginatedStores =
		filteredStores && filteredStores.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

	const handleDirectionButtonClick = (store, index) => {
		if (store && store?.distance) {
			setZoomInStore(null);
		}
		if (!store?.distance) {
			setZoomInStore(paginatedStores[index]);
			if (selected === index && zoomInStore !== null) {
				setZoomInStore(null);
			}
		}
		setSelected(index);
	};
	const handleClearClick = () => {
		setSearchStore("");
	};

	const handleViewChange = (mode) => {
		setViewMode(mode);
	};

	const handleGoogleMapsButtonClick = (store) => {
		const origin = encodeURIComponent(searchStore);
		const destination = encodeURIComponent(store.address);
		const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${origin}&destination=${destination}`;
		window.open(googleMapsUrl, "_blank");
	};

	useEffect(() => {
		if (!searchStore || searchStore === "") {
			setIsValid(true);
			setLockSearch(false);
			setError("");
		} else if (!isValid && searchStore && searchStore !== "") {
			setIsValid(true);
			setError("");
		}
	}, [searchStore, isValid]);

	useEffect(() => {
		if (stores.length > 0 && searchStore === "") {
			dispatch(filteredAndSortedStores({ searchStore: searchStore }));
			dispatch(getStoreDirection({ origin: searchStore, destination: paginatedStores[selected]?.address }));
			setSelected(0);
		}
	}, [stores, searchStore]);

	useEffect(() => {
		if (searchStore !== "" && paginatedStores[selected]?.distance !== undefined) {
			dispatch(getStoreDirection({ origin: searchStore, destination: paginatedStores[selected]?.address }));
		} else {
			storeDirection = undefined;
		}
	}, [searchStore, paginatedStores[selected]?.distance]);

	useEffect(() => {
		if (zoomInStore === null) {
			setMapState({
				center: {
					lat: 45.51681,
					lng: -73.45519,
				},
				zoom: 4,
			});
		} else {
			setMapState({
				center: {
					lat: parseFloat(zoomInStore?.lat),
					lng: parseFloat(zoomInStore?.lng),
				},
				zoom: 15,
			});
		}
	}, [zoomInStore]);

	return (
		<Fragment>
			<div className={classes.header}>
				<h1 ref={ref} className={classes.title}>
					{t(keys.FIND_STORE.STORE.TITLE)}
				</h1>
				<h2 className={classes.subTitle}>{t(keys.FIND_STORE.STORE.SUBTITLE)}</h2>
				<form onSubmit={searchSubmit}>
					<div className={classes.searchLayout}>
						<Autocomplete
							className={classes.searchInput}
							onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
							onPlaceChanged={() => {
								const place = autocompleteRef.current.getPlace();
								setSearchStore(place.formatted_address);
							}}
						>
							<TextInput
								className={classes.searchInput}
								inputclass={classes.inputclass}
								searchClass={classes.searchClass}
								placeholder={t(keys.FIND_STORE.META.PLACEHOLDER)}
								onChange={searchChangeHandler}
								value={searchStore}
								search={searchSubmit}
								isValid={isValid}
								error={error}
							/>
						</Autocomplete>
					</div>
					<div className={classes.buttons}>
						<Button type="submit" value="submit" size="big" className={classes.applyBtn}>
							{t(keys.FIND_STORE.META.FIND)}
						</Button>
						<Button size="big" className={classes.clearBtn} onClick={handleClearClick}>
							{t(keys.FIND_STORE.META.CLEAR)}
						</Button>
					</div>
					{gridWidth < 833 && (
						<div className={classes.viewButtons}>
							<p
								className={viewMode === "list" ? classes.active : ""}
								onClick={() => handleViewChange("list")}
							>
								{t(keys.FIND_STORE.META.LIST)}
							</p>
							<p
								className={viewMode === "map" ? classes.active : ""}
								onClick={() => handleViewChange("map")}
							>
								{t(keys.FIND_STORE.META.MAP)}
							</p>
						</div>
					)}
				</form>
			</div>
			<div
				className={classes.container}
				style={{ height: gridWidth <= 833 && (viewMode === "list" ? "40rem" : "60rem") }}
				ref={gridRef}
			>
				{(isLoading || isLoadingStoresSearch) && <Loading type={"bounce"} />}
				{!isLoading && !isLoadingStoresSearch && (
					<Fragment>
						{(gridWidth >= 833 || viewMode === "map") && (
							<div className={classes.map}>
								{stores && stores.length > 0 && (
									<GoogleMap
										center={mapState.center}
										zoom={mapState.zoom}
										mapContainerStyle={{ width: "100%", height: "100%" }}
										options={{ streetViewControl: false }}
									>
										<MarkerClusterer>
											{(clusterer) =>
												stores.map((store) => (
													<Marker
														key={store.id}
														position={{
															lat: parseFloat(store.lat),
															lng: parseFloat(store.lng),
														}}
														clusterer={clusterer}
														icon={{
															url:
																store.icon_type === 1
																	? "/static/favicon/Rona_FavIcon.png"
																	: "/static/favicon/Google-Map-Icon.png",
															scaledSize:
																window.google && window.google.maps
																	? store.icon_type === 1
																		? new window.google.maps.Size(30, 30)
																		: new window.google.maps.Size(25, 35)
																	: undefined,
														}}
														onClick={() => setSelectedStore(store)}
													/>
												))
											}
										</MarkerClusterer>

										{storeDirection !== undefined && (
											<DirectionsRenderer
												directions={storeDirection}
												options={{ suppressMarkers: true }}
											/>
										)}

										{storeDirection !== undefined &&
											storeDirection?.routes[0]?.legs[0]?.start_location && (
												<Marker
													position={storeDirection?.routes[0]?.legs[0]?.start_location}
													label=""
												/>
											)}

										{selectedStore?.address && (
											<InfoWindow
												position={{
													lat: parseFloat(selectedStore.lat),
													lng: parseFloat(selectedStore.lng),
												}}
												onCloseClick={() => setSelectedStore(null)}
											>
												<div className={classes.infoWindow}>
													<div className={classes.title}>{selectedStore.name}</div>
													{selectedStore.address && (
														<p>
															{t(keys.CONTACT.ADDRESS)} {selectedStore.address}{" "}
														</p>
													)}
													{selectedStore.phone && (
														<p>
															{t(keys.CONTACT.PHONE)}
															<a href={`tel:${selectedStore.phone}`}>
																{selectedStore.phone}
															</a>
														</p>
													)}
													{selectedStore.store_url && (
														<CustomNavLink
															to={selectedStore.store_url ? selectedStore.store_url : ""}
															target={selectedStore.store_url ? "_blank" : ""}
															className={classes.links}
														>
															{t(keys.FIND_STORE.META.VIEW_WEBSITE)}
														</CustomNavLink>
													)}
													<Button
														onClick={() => handleGoogleMapsButtonClick(selectedStore)}
														className={classes.Website}
													>
														{t(keys.FIND_STORE.META.GET_DIRECTION)}
													</Button>
												</div>
											</InfoWindow>
										)}
									</GoogleMap>
								)}
							</div>
						)}
						{(viewMode === "list" || viewMode === "map") && (
							<div
								style={{ height: gridWidth <= 833 && (viewMode === "list" ? "100%" : "60%") }}
								className={classes.subContainer}
							>
								<div className={classes.searchContainer}>
									<div className={classes.search}>
										<TextField
											className={classes.searchInputName}
											label={t(keys.FIND_STORE.META.FIND_BY_NAME)}
											value={searchTerm}
											onChange={(e) => setSearchTerm(e.target.value)}
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														{searchTerm ? (
															<IconButton onClick={() => setSearchTerm("")}>
																<Icon
																	className={classes.icon}
																	icon="ic:baseline-clear"
																/>
															</IconButton>
														) : (
															<IconButton>
																<Icon
																	className={classes.icon}
																	icon="ic:baseline-search"
																/>
															</IconButton>
														)}
													</InputAdornment>
												),
											}}
										/>
									</div>
								</div>
								{paginatedStores.length === 0 && (
									<div className={classes.noResult}>{t(keys.FIND_STORE.META.No_RESULT_FOUND)}</div>
								)}
								<div className={classes.list}>
									{paginatedStores &&
										paginatedStores.map((store, index) => (
											<div className={classes.address} key={store.id}>
												<div
													className={`${classes.continue} ${
														(storeDirection || zoomInStore) && selected === index
															? classes.selected
															: ""
													}`}
													onClick={() => handleDirectionButtonClick(store, index)}
												>
													<div className={classes.title}>
														<div className={classes.description}>{store.name}</div>
														{!validateAddress(searchStore) && store.distance && (
															<div className={classes.distance}>
																<Icon
																	className={classes.icon}
																	icon="majesticons:map-marker-area"
																/>
																<p>{`${store.distance}KM`}</p>
															</div>
														)}
													</div>
													{store.address && (
														<p>
															{t(keys.CONTACT.ADDRESS)} {store.address}{" "}
														</p>
													)}
													{store.phone && (
														<p>
															{t(keys.CONTACT.PHONE)}
															<a href={`tel:${store.phone}`}>{store.phone}</a>
														</p>
													)}
													{store.store_url && (
														<CustomNavLink
															to={store.store_url ? store.store_url : ""}
															target={store.store_url ? "_blank" : ""}
															className={classes.links}
														>
															{t(keys.FIND_STORE.META.VIEW_WEBSITE)}
														</CustomNavLink>
													)}
													<Button
														onClick={() => handleGoogleMapsButtonClick(store)}
														className={classes.Website}
													>
														{t(keys.FIND_STORE.META.GET_DIRECTION)}
													</Button>
												</div>
											</div>
										))}
								</div>
								{filteredStores?.length > 0 && (
									<div className={classes.pagination}>
										<Button onClick={handlePrev} disabled={currentPage === 1}>
											{t(keys.FIND_STORE.META.PREVIOUS)}
										</Button>
										<span>
											{t(keys.FIND_STORE.META.PAGE)} {currentPage} {t(keys.FIND_STORE.META.OF)}{" "}
											{Math.ceil(filteredStores?.length / itemsPerPage)}
										</span>
										<Button
											onClick={handleNext}
											disabled={currentPage === Math.ceil(filteredStores?.length / itemsPerPage)}
										>
											{t(keys.FIND_STORE.META.NEXT)}
										</Button>
									</div>
								)}
							</div>
						)}
					</Fragment>
				)}
			</div>
		</Fragment>
	);
});

Stores.defaultProps = {
	classes,
};

export default Stores;

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { keys } from "../../../locales/localeskeys";

import { EmptyState, HeaderPage } from "../../../commons";
import Accordion from "../../ui/Accordion/Accordion";
import Loading from "../../ui/loading/Loading";
import Pagination from "../../ui/Pagination/Pagination";

import { CheckboxInput, RadioInput, TextInput, MultiSelectDropdown, SelectDropdown } from "../../../atoms/forms";
import OrderItem from "./orderItem/OrderItem";

import { useSearch } from "../../../hooks";
import { searchSelector } from "../../../store/productQuery";

import { isLoggedInSelector, setPrecedentUrl } from "../../../store/auth";
import {
	countSelector,
	getReports,
	isLoadingSelector,
	pageSelector,
	reportsSelector,
	setPage,
	setSearchFilter,
	setStatusFilter,
	setDateRangeFilter,
	isPrintingSelector,
} from "../../../store/report";

import classes from "./Orders.module.scss";

import emptyBox from "../../../assets/svg/empty-box.svg";


const Orders = ({ classes }) => {
	const dispatch = useDispatch();

	const { t, i18n } = useTranslation();
	const lngId = i18n.language;

	const navigate = useNavigate();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	const statusOptions = [
		t(keys.ACCOUNT.ORDERS.OPEN),
		t(keys.ACCOUNT.ORDERS.CONFIRMED),
		t(keys.ACCOUNT.ORDERS.DELIVERED),
		t(keys.ACCOUNT.ORDERS.INVOICED),
		t(keys.ACCOUNT.ORDERS.CANCELED),
		t(keys.ACCOUNT.ORDERS.PENDING),
	];

	const dateRangeOptions = [
		t(keys.ACCOUNT.ORDERS.FILTERS.DATE_RANGE_ALL),
		t(keys.ACCOUNT.ORDERS.FILTERS.DATE_RANGE_7_DAYS_AGO),
		t(keys.ACCOUNT.ORDERS.FILTERS.DATE_RANGE_1_MONTH_AGO),
		t(keys.ACCOUNT.ORDERS.FILTERS.DATE_RANGE_6_MONTHS_AGO),
		t(keys.ACCOUNT.ORDERS.FILTERS.DATE_RANGE_1_YEAR_AGO),
	];

	const [dateRange, setDateRange] = useState(dateRangeOptions[0]);
	const [status, setStatus] = useState([]);

	const isLoggedIn = useSelector(isLoggedInSelector);
	const isLoading = useSelector(isLoadingSelector);
	const reports = useSelector(reportsSelector);
	const currentPage = useSelector(pageSelector);
	const totalOrders = useSelector(countSelector);

	const searchState = useSelector(searchSelector);
	const { searchChangeHandler, enterSubmitHandler } = useSearch({
		onSearchChange: (value) => {
			dispatch(setSearchFilter(value));
		},
		onSearchSubmit: (_) => {
			dispatch(setPage(1));
			dispatch(getReports());
		},
	});

	const handleStatusMultiSelectChange = (event) => {
		setStatus(event.target.value);
	};

	const handleStatusCheckboxChange = (event) => {
		const value = event.target.value;
		setStatus((prevStatus) => {
			if (prevStatus.includes(value)) {
				return prevStatus.filter((status) => status !== value);
			} else {
				return [...prevStatus, value];
			}
		});
	};

	const handleDateRangeChange = (event) => {
		setDateRange(event.target.value);

		const dateRangeKey = dateRangeOptions.indexOf(event.target.value);
		dispatch(setDateRangeFilter(dateRangeKey));
		dispatch(getReports());
	};

	const handleResetFilters = () => {
		searchChangeHandler({ target: { value: "" } });
		dispatch(setSearchFilter(""));
		
		setStatus([]);
		dispatch(setStatusFilter([]));
		
		setDateRange(dateRangeOptions[0]);
		dispatch(setDateRangeFilter(0));
		
		dispatch(setPage(1));
		dispatch(getReports());
	}

	const handleGoToProducts = () => {
		handleResetFilters();
		navigate({
			pathname: "/adstore",
			search: queryParams.toString(),
		});
	}

	const handleChangePage = (page) => {
		window.scroll({ top: 0, left: 0 });
		dispatch(setPage(page));
		dispatch(getReports());
	};

	useEffect(() => {
		setDateRange(dateRangeOptions[0]);
		setStatus([]);
    }, [lngId]);

	useEffect(() => {
		const statusKeys = status.map((item) => statusOptions.indexOf(item));

		dispatch(setStatusFilter(statusKeys));
		dispatch(getReports());
	}, [status]);

	useEffect(() => {
		if (!isLoggedIn) {
			dispatch(setPrecedentUrl({ pathname: location.pathname, search: location.search }));
			navigate({ pathname: `/sign-in`, search: queryParams.toString() });
		}
	}, [isLoggedIn, lngId]);

	useEffect(() => {
		if (isLoggedIn === true) {
			dispatch(getReports());
		}
	}, [isLoggedIn]);

	return (
		<section className="pages-layout">
			<HeaderPage 
				tree={[
					{ label: t(keys.ACCOUNT.ACCOUNT.TITLE), to: `/account?lng=${lngId}` },
					{ label: t(keys.ACCOUNT.ORDERS.TITLE), to: `/orders?lng=${lngId}` },
				]}
				title={t(keys.ACCOUNT.ORDERS.TITLE)}
			/>
			<main className={classes.main}>
				<div className={classes.filters}>
					<TextInput
						label={t(keys.GLOBAL.SEARCH)}
						value={searchState || ""}
						onChange={searchChangeHandler}
						onKeyDown={enterSubmitHandler}
						type="search"
					/>
					<Accordion
						className={classes.accordion}
						header={t(keys.GLOBAL.COMMON.STATUS)}
						headerClass={classes.accordionHeader}
						headerClassActive={classes.accordionHeaderActive}
						iconClass={classes.accordionIconClass}
						accordionBody={classes.accordionBody}
						active={true}
					>
						<div className={classes.accordionContent}>
							{statusOptions.map((item, key) => (
								<CheckboxInput
									key={key}
									id={`checkbox_statusOptions_${key}`}
									label={item}
									value={item}
									checked={status.indexOf(item) > -1}
									onChange={handleStatusCheckboxChange}
								/>
							))}
						</div>
					</Accordion>
					<Accordion
						className={classes.accordion}
						header={t(keys.ACCOUNT.ORDERS.FILTERS.DATE_RANGE)}
						headerClass={classes.accordionHeader}
						headerClassActive={classes.accordionHeaderActive}
						iconClass={classes.accordionIconClass}
						accordionBody={classes.accordionBody}
						active={true}
					>
						<div className={classes.accordionContent}>
							{dateRangeOptions.map((item, key) => (
								<RadioInput
									key={key}
									id={`radio_dateRangeOptions_${key}`}
									label={item}
									value={item}
									checked={dateRange == item}
									onChange={handleDateRangeChange}
								/>
							))}
						</div>
					</Accordion>
				</div>

				<div className={classes.orders_list}>
					<div className={classes.filters}>
						<TextInput
							label={t(keys.GLOBAL.SEARCH)}
							value={searchState || ""}
							onChange={searchChangeHandler}
							onKeyDown={enterSubmitHandler}
							type="search"
						/>
						<MultiSelectDropdown
							label={t(keys.GLOBAL.COMMON.STATUS)}
							id="multiselect_statusOptions"
							options={statusOptions}
							value={status}
							onChange={handleStatusMultiSelectChange}
						/>
						<SelectDropdown
							label={t(keys.ACCOUNT.ORDERS.FILTERS.DATE_RANGE)}
							id="select_dateRangeOptions"
							options={dateRangeOptions}
							value={dateRange}
							onChange={handleDateRangeChange}
						/>
					</div>

					{(isLoading) && <Loading type={"linear"} />}
					{!isLoading && reports && reports.length > 0 && (
						<>
							{reports.map((report) => (
								<OrderItem key={report.id} report={report} />
							))}

							<Pagination
								className={classes.pagination}
								currentPage={currentPage}
								totalCount={totalOrders}
								pageSize={5}
								onPageChange={(page) => handleChangePage(page)}
							/>
						</>
					)}
					{!isLoading && reports && reports.length == 0 && (
						<>
							{searchState === "" && dateRange === dateRangeOptions[0] && status.length === 0 ? (
								<EmptyState
									imageAlt="Orders not found"
									title={t(keys.ACCOUNT.ORDERS.EMPTY_STATES.ORDERSNOTFOUND.HEADER)}
									messages={[
										t(keys.ACCOUNT.ORDERS.EMPTY_STATES.ORDERSNOTFOUND.MESSAGE),
										t(keys.ACCOUNT.ORDERS.EMPTY_STATES.ORDERSNOTFOUND.ACTION)
									]}
									onClick={handleGoToProducts}
									buttonLabel={keys.ACCOUNT.ORDERS.EMPTY_STATES.ORDERSNOTFOUND.BUTTON}
								/>
							) : (
								<EmptyState
									imageAlt="Orders not found"
									title={t(keys.ACCOUNT.ORDERS.EMPTY_STATES.MATCHESNOTFOUND.HEADER)}
									messages={[
										t(keys.ACCOUNT.ORDERS.EMPTY_STATES.MATCHESNOTFOUND.MESSAGE),
										t(keys.ACCOUNT.ORDERS.EMPTY_STATES.MATCHESNOTFOUND.ACTION)
									]}
									onClick={handleResetFilters}
									buttonLabel={keys.ACCOUNT.ORDERS.EMPTY_STATES.MATCHESNOTFOUND.BUTTON}
								/>
							)}
						</>
					)}
				</div>
			</main>
		</section>
	);
};

Orders.defaultProps = {
	classes,
};

export default Orders;

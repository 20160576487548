import { useMemo } from "react";
import { keys } from "../locales/localeskeys";

export const usePaymentMethod = (t) => {
	const cardDescriptionMap = {
		VISA: "VISA",
		MC: "Master Card",
		DISC: "Discover",
		DC: "Diners Club",
		AMEX: "American Express",
		JCB: "JCB",
		UP: "Union Pay",
	};
    
    const getCardType = (cardType) => {
        return cardType === "DEBITCARD"
            ? t(keys.ACCOUNT.PAYMENT_METHODS.DEBIT_CARD)
            : t(keys.ACCOUNT.PAYMENT_METHODS.CREDIT_CARD);
    };

	const extendedPaymentMethodInfo = ({ card_short_description, card_type, card_number }) => {

		const cardDescription = cardDescriptionMap[card_short_description] || card_short_description;
		const shortCardType = getCardType(card_type).replace(" card", "");

		const label = `${cardDescription} ${shortCardType}`;
		const description = `${getCardType(card_type)} ${t(keys.ACCOUNT.PAYMENT_METHODS.ENDING_IN)} **** ${card_number.substr(-4)}`;

		return { label, description };
	};

	return { extendedPaymentMethodInfo };
};

import React, { createContext, useContext } from "react";

// Creating a context to store form action handlers
const FormActionsContext = createContext();

/**
 * FormActionsProvider is a React context provider that manages a callback function.
 * This callback can be used to handle form-related actions like saving or canceling.
 * 
 * @param {React.ReactNode} children - The child components that will have access to the context.
 * @param {Function} callback - A function executed when handleOnCallback is triggered.
 */
export const FormActionsProvider = ({ children, callback = () => {} }) => {
    
    // Function that triggers the provided callback
    const handleOnCallback = () => callback();

    return (
        <FormActionsContext.Provider value={{ handleOnCallback }}>
            {children}
        </FormActionsContext.Provider>
    );
};

/**
 * Custom hook to access the form actions context.
 * 
 * @param {Function} callback - A fallback callback function in case the context is not available.
 * @returns {Object} An object containing the handleOnCallback function.
 */
export const useFormActions = (callback = () => {}) => {
    
    // Accessing the context
    const context = useContext(FormActionsContext);

    // If the context is available, return its callback function.
    if (context) {
        return {
            handleOnCallback: context.handleOnCallback,
        };
    }

    // If the context is not available, return a local callback function.
    return {
        handleOnCallback: () => callback(),
    };
};

import React from "react";
import ReactDOM from "react-dom";
import { Icon } from "@iconify/react";

import classes from "./Modal.module.scss";

export const Modal = ({ classes, header, children, onClose, allowCloseModal }) => {
	const portalElement = document.getElementById("overlays");

	return (
		<>
			{ReactDOM.createPortal(<div className={classes.backdrop} onClick={onClose} />, portalElement)}
			{ReactDOM.createPortal(
				<section className={classes.modal}>
					<header>
                        <div>{header}</div>
						{allowCloseModal && (
							<div><Icon className={classes.icon} onClick={onClose} icon="bi:x-lg" /></div>
						)}
                    </header>
					<main>{children}</main>
				</section>,
				portalElement
			)}
		</>
	);
};

Modal.defaultProps = {
	classes,
    header: "",
    children: "",
    onClose: () => {},
	allowCloseModal: true,
};

import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { keys } from "../../../locales/localeskeys";

import { CardWrapper } from "../../../commons";

import { useCheckout } from "../../../providers";
import { useOrderLine } from "../../../hooks";
import { isLoadingSelector } from "../../../store/cart";

import { Button, Spinner } from "../../../atoms";

export const Summary = () => {
	const { t, i18n } = useTranslation();
	const lngId = i18n.language;

	const { classes, subTotal } = useCheckout();
	const { formatCurrency } = useOrderLine(t, lngId);

    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

	const isLoading = useSelector(isLoadingSelector)
	const status = useSelector((state) => state.cart.status)

    const handleOnCheckout = () => {
		if(status === "succeeded"){
			window.scrollTo(0, 0);
			navigate({ pathname: `/checkout`, search: queryParams.toString() });
		}
    }

    const handleOnContinue = () => {
		window.scrollTo(0, 0);
        navigate({ pathname: `/adstore`, search: queryParams.toString() });
    }

	return (
		<CardWrapper
			classNameWrapper={classes.checkout_card}
			header={<div className={classes.label}>{t(keys.CART.OVERVIEW.SUMMARY.TITLE)}</div>}
		>
			<div className={classes.checkout_detail}>
				<div>{t(keys.CHECKOUT.SUMMARY.SUBTOTAL)}</div>
				<div className={classes.align_right}>
					{isLoading ? <Spinner /> : formatCurrency(subTotal)} 
				</div>
			</div>

			<Button onClick={handleOnCheckout} disabled={subTotal === 0}>
				{t(keys.CART.OVERVIEW.SUMMARY.CHECKOUT)}
			</Button>

            <Button color="outlined" onClick={handleOnContinue}>
				{t(keys.CART.OVERVIEW.SUMMARY.CONTINUE)}
			</Button>
		</CardWrapper>
	);
};

import React from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Icon } from '@iconify/react';
import clsx from "clsx";

import { keys } from '../../locales/localeskeys';

import classes from "./Checkout.module.scss";
import { Button } from '../../atoms/button/Button';
import { clear } from '../../store/cart';

export const PurchaseSuccess = ({classes}) => {
    const dispatch = useDispatch();

    const { t } = useTranslation();

    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const handleOnClick = () => {
        dispatch(clear());
        
        window.scrollTo(0, 0);
        navigate({ pathname: `/`, search: queryParams.toString() });
    }

    return (
        <div className={clsx(classes.align_center, classes.purchaseSuccess)}>
            <Icon className={classes.icon} icon="clarity:success-standard-solid" />
            <div className={classes.bold}>{t(keys.CHECKOUT.SUMMARY.SUCCESS_SUB_TITLE)}</div>
            <div>{t(keys.CHECKOUT.SUMMARY.SUCCESS_TEXT1)}</div>
            <div>{t(keys.CHECKOUT.SUMMARY.SUCCESS_TEXT2)}</div>
            <div>
                <Button onClick={handleOnClick}>{t(keys.CHECKOUT.SUMMARY.SUCCESS_BTN)}</Button>
            </div>
        </div>
    )
}

PurchaseSuccess.defaultProps = {
    classes,
}
import React, { useState, useRef, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import { Icon } from "@iconify/react";

import { keys } from "../../../../locales/localeskeys";

import { useLocalized, useOrderLine } from "../../../../hooks";

import { deleteLine, updateLine } from "../../../../store/cart";

import { Button, TextInput } from "../../../../atoms";
import CustomNavLink from "../../../ui/customNavLink/CustomNavLink";

const debounce = (func, delay) => {
	let timer;
	return (...args) => {
		clearTimeout(timer);
		timer = setTimeout(() => func(...args), delay);
	};
};

const OrderLine = ({ classes, orderLine }) => {
	const dispatch = useDispatch();

	const { t, i18n } = useTranslation();
	const lngId = i18n.language;

	const { getLocalizedValueByKeyType } = useLocalized(lngId);
	const { processedOrderLine, formatCurrency, formatPackaging } = useOrderLine(t, lngId);

	const { brand, item, image, label, specification, format, variant, unitAmount, packaging } =
		processedOrderLine(orderLine);
	const { inStock, packagingFormat } = formatPackaging(format, variant);

	const search = `?lng=${lngId}`;

	const [qtyPack, setQtyPack] = useState(Number(orderLine.qty_pack));
	const qtyPackRef = useRef();

	const qty = useMemo(() => qtyPack * (packaging || 1), [qtyPack, packaging]);

	const handleOnRemove = () => {
		dispatch(deleteLine(orderLine.id));
	};

	const handleOnIncrement = () => {
		qtyPackRef.current.value = Number(qtyPackRef.current.value) + 1;
		setQtyPack(qtyPackRef.current.value);
		debouncedUpdateLine(qtyPackRef.current.value);
	};

	const handleOnSet = (e) => {
		qtyPackRef.current.value = e.target.value ? Number(e.target.value) : 1;
		setQtyPack(qtyPackRef.current.value);

		if (Number(qtyPack) != Number(qtyPackRef.current.value)) {
			debouncedUpdateLine(qtyPackRef.current.value);
		}
	};

	const handleOnDecrement = () => {
		if (qtyPackRef.current.value > 1) {
			qtyPackRef.current.value = Number(qtyPackRef.current.value) - 1;
			setQtyPack(qtyPackRef.current.value);
			debouncedUpdateLine(qtyPackRef.current.value);
		}
	};

	const debouncedUpdateLine = useCallback(
		debounce((newQtyPack) => {
			const newQty = newQtyPack * (packaging || 1);

			dispatch(
				updateLine({
					id: orderLine.id,
					variant_id: variant.id,
					qty: newQty,
					qty_pack: newQtyPack,
					unitAmount: unitAmount,
					amount: (newQty * unitAmount).toFixed(2),
					cartOpen: false,
				})
			);
		}, 350),
		[dispatch, orderLine.id, variant.id, unitAmount, packaging]
	);

	return (
		<>
			<div>
				<CustomNavLink
					to={
						brand
							? `/adstore/overview/${item.erp_code}/${brand?.erp_code}/${search}`
							: `/adstore/overview/${item.erp_code}/${search}`
					}
				>
					<img src={image} alt={label} />
				</CustomNavLink>
			</div>
			<div className={classes.content}>
				<CustomNavLink
					to={
						brand
							? `/adstore/overview/${item.erp_code}/${brand?.erp_code}/${search}`
							: `/adstore/overview/${item.erp_code}/${search}`
					}
				>
					<div className={classes.textlink}>{label}</div>
				</CustomNavLink>
				<div className={clsx(classes.align_right, classes.bold)}>{formatCurrency(qty * unitAmount)}</div>
				<div>
					{specification?.erp_code && specification?.hex && (
						<a
							href={
								brand && specification && format
									? `/adstore/variants/${item?.erp_code}/${brand?.erp_code}/${specification?.erp_code}/${format?.erp_code}/${search}`
									: `/adstore/overview/${item?.erp_code}/${search}`
							}
						>
							{`${specification?.erp_code} ${getLocalizedValueByKeyType(specification, "description")}`}
						</a>
					)}
				</div>
				<div className={clsx(classes.align_right, "caption")}>{`${qty} x ${formatCurrency(unitAmount)}`}</div>
				<div className={classes.full_width}>
					{unitAmount && (format?.description_fr || format?.description_en) && (
						<>
							<span className={classes.bold}>{t(keys.CART.OVERVIEW.ORDER_LINES.FORMAT)}: </span>
							<span>{getLocalizedValueByKeyType(format, "description")}</span>
						</>
					)}
				</div>
				<div className={clsx(classes.full_width, "caption", classes.green)}>
					{`${inStock} ${packagingFormat} ${t(keys.CART.OVERVIEW.AVAILABLE)}`}
				</div>
				<div className={clsx(classes.full_width, "caption")}>
					({`${packagingFormat} ${t(keys.GLOBAL.COMMON.OF)} ${t(packaging)}`})
				</div>
				<div className={classes.controls}>
					<div>
						<Button iconBorderLess color="outlined" onClick={handleOnDecrement} disabled={qtyPack <= 1}>
							<Icon icon="ic:baseline-minus" width="24" height="24" />
						</Button>
					</div>
					<div className={classes.controlInput}>
						<TextInput
							type="number"
							value={Number(orderLine.qty_pack)}
							inputProps={{ min: 1 }}
							onChange={handleOnSet}
							ref={qtyPackRef}
						/>
					</div>
					<div>
						<Button iconBorderLess color="outlined" onClick={handleOnIncrement}>
							<Icon icon="ic:baseline-plus" width="24" height="24" />
						</Button>
					</div>
				</div>
				<div className={classes.align_right}>
					<div className={clsx(classes.removeString, classes.textlink, classes.red)} onClick={handleOnRemove}>
						{t(keys.CART.OVERVIEW.REMOVE)}
					</div>
					<div className={clsx(classes.removeIcon)}>
						<Button iconBorderLess color="danger-outlined" onClick={handleOnRemove}>
							<Icon icon="pepicons-pencil:trash" width="24" height="24" />
						</Button>
					</div>
				</div>
			</div>
		</>
	);
};

export default OrderLine;

import React, { useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

import { keys } from "../../../locales/localeskeys";

import { FormActionsProvider, PAYMENT_METHOD_STEP, PLACE_ORDER_STEP, useCheckout } from "../../../providers";

import { updateOrder } from "../../../store/cart";
import {
	deletePaymentMethod,
	paymentMethodSelector,
	paymentMethodsSelector,
	setPaymentMethod,
} from "../../../store/paymentMethod";

import { useLocalized, usePaymentMethod } from "../../../hooks";

import { RadioInput, Button, Alert } from "../../../atoms";

import { PaymentMethodsForm } from "../../account/paymentMethods/paymentMethodsForm/PaymentMethodsForm";
import { CardWrapper, ListCardItem, Modal } from "../../../commons";

export const PaymentMethod = () => {
	const dispatch = useDispatch();

	const { t, i18n } = useTranslation();
	const lngId = i18n.language;

	const { classes, order, currentStep, setCurrentStep, handleOnNextStep, isEditable, subTotal, isAvailableShippingFees, shippingFees } =
		useCheckout();
	const { getLocalizedValueByKeyType } = useLocalized(lngId);

	const status = useSelector((state) => state.cart.statusUpdateOrder);
	const error = useSelector((state) => state.cart.error);

	const { extendedPaymentMethodInfo } = usePaymentMethod(t);
	const paymentMethods = useSelector(paymentMethodsSelector);
	const paymentMethod = useSelector(paymentMethodSelector);

	const [paymentMethodSelected, setPaymentMethodSelected] = useState(order?.payment_method?.id);
	const [paymentMethodModal, setPaymentMethodModal] = useState(false);

	const isStepAccessible = useMemo(
		() => currentStep === PAYMENT_METHOD_STEP || isEditable(PAYMENT_METHOD_STEP),
		[currentStep]
	);

	const isDisabled = useMemo(
		() => currentStep !== PAYMENT_METHOD_STEP && isEditable(PAYMENT_METHOD_STEP),
		[currentStep]
	);

	const isLoading = useMemo(() => status === "loading" && currentStep === PAYMENT_METHOD_STEP, [status, currentStep]);

	const processedPaymentModals = useMemo(() => {
		const defaultPaymentMethod = paymentMethods.filter((pm) => pm.is_default);
		if (defaultPaymentMethod.length > 0 && !order?.payment_method) {
			setPaymentMethodSelected(defaultPaymentMethod[0].id);
		}

		return paymentMethods.sort((a, b) => {
			if (a.is_default === b.is_default) {
				return a.name.localeCompare(b.name);
			}
			return a.is_default ? -1 : 1;
		});
	}, [paymentMethods]);

	const handlePaymentMethod = {
		open: () => setPaymentMethodModal(true),
		close: () => setPaymentMethodModal(false),
	};

	const handleOnSave = (e) => {
		e.preventDefault();

		dispatch(
			updateOrder({
				payment_method_id: e.target.elements.payment_method.value,
				amount: (subTotal + (isAvailableShippingFees && shippingFees ? shippingFees : 0)).toFixed(2),
				step: PAYMENT_METHOD_STEP,
			})
		);
	};

	const handleOnEdit = (paymentMethod) => {
		dispatch(setPaymentMethod(paymentMethod.id));
		setPaymentMethodModal(true);
	};

	const handleOnRemove = (paymentMethod) => {
		dispatch(deletePaymentMethod(paymentMethod.id));
	};

	useEffect(() => {
		if (status === "succeeded" && currentStep === PAYMENT_METHOD_STEP) {
			handleOnNextStep(PLACE_ORDER_STEP);
		}
	}, [status]);

	return (
		<>
			<CardWrapper
				classNameWrapper={classes.checkout_card}
				header={
					<>
						<div className={classes.label}>{t(keys.CHECKOUT.MAIN.PAYMENT_METHOD.TITLE)}</div>
						{isEditable(PAYMENT_METHOD_STEP) && (
							<div className={classes.textlink} onClick={() => setCurrentStep(PAYMENT_METHOD_STEP)}>
								{t(keys.CHECKOUT.MAIN.COMMONS.EDIT)}
							</div>
						)}
					</>
				}
			>
				{isStepAccessible && (
					<form onSubmit={handleOnSave}>
						{error && currentStep === PAYMENT_METHOD_STEP && (
							<Alert severity="error">{getLocalizedValueByKeyType(error, "description")}</Alert>
						)}

						<div className={classes.bold}>{t(keys.CHECKOUT.MAIN.PAYMENT_METHOD.PAYMENT_MODE)}:</div>
						<ListCardItem
							classNameWrapper={classes.checkout_list_item}
							items={processedPaymentModals.map((pm) => {
								const { label, description } = extendedPaymentMethodInfo(pm);

								return (
									<>
										<div>
											<RadioInput
												name="payment_method"
												value={pm.id}
												checked={paymentMethodSelected === pm.id}
												onChange={() => setPaymentMethodSelected(pm.id)}
												required={true}
												disabled={isDisabled}
											/>
										</div>
										<div className={classes.content}>
											<div>{label}</div>
											<div>{description}</div>
											{!isDisabled && (
												<div className={classes.align_right}>
													{!pm.is_default && (
														<div
															className={classes.textlink}
															onClick={() => handleOnEdit(pm)}
														>
															{t(keys.CHECKOUT.MAIN.COMMONS.EDIT)}
														</div>
													)}
													<div
														className={clsx(classes.textlink, classes.red)}
														onClick={() => handleOnRemove(pm)}
													>
														{t(keys.CHECKOUT.MAIN.COMMONS.REMOVE)}
													</div>
												</div>
											)}
										</div>
									</>
								);
							})}
						/>

						{!isDisabled && (
							<>
								<div
									className={clsx(classes.textlink, classes.align_right)}
									onClick={handlePaymentMethod.open}
								>
									{t(keys.CHECKOUT.MAIN.PAYMENT_METHOD.ADD_PAYMENT_METHOD)}
								</div>

								<Button type="submit" loading={isLoading}>
									{t(keys.CHECKOUT.MAIN.COMMONS.CONTINUE)}
								</Button>
							</>
						)}
					</form>
				)}
			</CardWrapper>

			{paymentMethodModal && (
				<Modal
					header={
						paymentMethod.id
							? t(keys.CHECKOUT.MAIN.PAYMENT_METHOD.EDIT_PAYMENT_METHOD)
							: t(keys.CHECKOUT.MAIN.PAYMENT_METHOD.ADD_PAYMENT_METHOD)
					}
					onClose={handlePaymentMethod.close}
				>
					<FormActionsProvider callback={handlePaymentMethod.close}>
						<PaymentMethodsForm />
					</FormActionsProvider>
				</Modal>
			)}
		</>
	);
};

import { createTheme } from "@material-ui/core/styles";

const primaryColor = "#007AFF";

export const theme = createTheme({
	typography: {
		fontFamily: "'Montserrat', sans-serif",
	},
	palette: {
		primary: {
			main: primaryColor,
			dark: "#0D509B",
			darker: "#0E315D",
			light: "#3A96FC",
			lightest: "#EDFAFF",
			lighter: "#80BCFF",
		},
		secondary: {
			main: "#FFAA00",
			dark: "#CC8800",
			light: "#FFE5B3",
		},
		success: {
			main: "#28A745",
			dark: "#217239",
			light: "#A9D8B0",
		},
		alert: {
			main: "#FF4D4D",
			dark: "#CC3E3E",
			light: "#FFCCCC",
		},
		neutral: {
			dark: "#000000",
			light: "#FFFFFF",
			grey: {
				dark: "#333333",
				main: "#999999",
				light: "#F4F4F4",
			},
		},
	},
	overrides: {
		MuiInputBase: {
			root: {
				'& input[type="search"]:not(old):focus': {
					boxShadow: "none"
				},
				'& input[type="search"]::-webkit-search-cancel-button': {
					position: "relative",
					right: 5,
					"-webkit-appearance": "none",
					height: 16,
					width: 16,
					backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='%23999999' d='M8 2.0752C4.7 2.0752 2 4.7752 2 8.0752C2 11.3752 4.7 14.0752 8 14.0752C11.3 14.0752 14 11.3752 14 8.0752C14 4.7752 11.3 2.0752 8 2.0752ZM10.625 9.6502L9.575 10.7002L8 9.1252L6.425 10.7002L5.375 9.6502L6.95 8.0752L5.375 6.5002L6.425 5.4502L8 7.0252L9.575 5.4502L10.625 6.5002L9.05 8.0752L10.625 9.6502Z'/%3E%3C/svg%3E")`,
				},
			},
		},
		MuiInput: {
			underline: {
				"&:after": {
					borderBottomColor: primaryColor,
				},
				"&:hover:not(.Mui-disabled):before": {
					borderBottomColor: "#0D509B",
				},
			},
		},
		MuiInputLabel: {
			root: {
				"&$focused": {
					color: primaryColor,
				},
			},
		},
		MuiFormControl: {
			root: {
				width: "100%",
			},
		},
		MuiSelect: {
			select: {
				"&:focus": {
					backgroundColor: "#FFFFFF",
				},
			},
		},
		MuiCheckbox: {
			root: {
				"&:hover": {
					backgroundColor: "transparent",
				},
			},
		},
		MuiRadio: {
			root: {
				"&:hover": {
					backgroundColor: "transparent",
				},
			},
		},
		MuiChip: {
			root: {
				fontSize: "0.812rem",
				borderRadius: 0,
				backgroundColor: "#EDFAFF",
				color: primaryColor,
			},
		},
		MuiButton: {
			contained: {
				'&.Mui-disabled': {
					color: "#333333",
					backgroundColor: "#F4F4F4",
					borderColor: "#999999",
				}
			},
		},
		MuiAutocomplete: {
			root: {
				zIndex: 3000,
			},
			popper: {
				zIndex: 3001,
			}
		},
		MuiAlert: {
			root: {
				borderRadius: 0,
			}
		}
	},
});

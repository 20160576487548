import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { keys } from "../../../../locales/localeskeys";

import {
	shippingAddressesSelector,
	setShippingAddress,
	deleteShippingAddress,
} from "../../../../store/shippingAddress";

import { Button } from "../../../../atoms/button/Button";
import Card from "../../card/Card";

import classes from "./ShippingAddressList.module.scss";

export const ShippingAddressList = ({ classes }) => {
	const dispatch = useDispatch();

	const { t } = useTranslation();
	const shippingAddresses = useSelector(shippingAddressesSelector);
	const [availableShippingAddresses, setAvailableShippingAddresses] = useState([]);

	const navigate = useNavigate();

	const handleAddShippingAddress = () => {
		dispatch(setShippingAddress(null));
		navigate("/account/shipping-address");
	};

	const handleOnEdit = (address) => {
		dispatch(setShippingAddress(address.id));
		navigate("/account/shipping-address");
	};

	const handleOnRemove = (address) => {
		dispatch(deleteShippingAddress(address.id));
	};

	const sortAddresses = (addresses) => {
		return [...addresses].sort((a, b) => {
			if (a.is_default === b.is_default) {
				return a.name.localeCompare(b.name);
			}
			return a.is_default ? -1 : 1;
		});
	};

	useEffect(() => {
		if (shippingAddresses) {
			setAvailableShippingAddresses(sortAddresses(shippingAddresses));
			window.scrollTo(0, 0);
		}
	}, [shippingAddresses]);

	return (
		<div className={classes.frameset}>
			<div className={classes.header}>
				<Button onClick={handleAddShippingAddress} icon="material-symbols:add">
					{t(keys.ACCOUNT.ADDRESS.ADD_BUTTON_LABEL)}
				</Button>
			</div>
			<div className={classes.content}>
				{availableShippingAddresses.map((data, key) => (
					<Card
						key={key}
						title={data.name}
						onEdit={() => handleOnEdit(data)}
						onRemove={() => handleOnRemove(data)}
						showSetByDefault={false}
						showEdit={!data.is_default}
						showRemove={!data.is_default}
					>
						<div>{data.line1}</div>
						<div>{`${data.city}, ${data.state}, ${data.postal_code}, ${data.country}`}</div>
					</Card>
				))}
			</div>
		</div>
	);
};

ShippingAddressList.defaultProps = {
	classes,
};

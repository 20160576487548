import React from "react";

import classes from "./ListCardItem.module.scss";

export const ListCardItem = ({ classes, classNameWrapper, items }) => {
	return (
		<section className={classes.list_card_item}>
			{items.map((item, key) => (
				<article key={key} className={classNameWrapper}>
					{item}
				</article>
			))}
		</section>
	);
};

ListCardItem.defaultProps = {
	classes,
	classNameWrapper: "",
	items: [],
};

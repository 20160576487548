import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";
import SideCart from "../sidecart/SideCart";
import NavDrop from "./NavDrop/NavDrop";
import NavOverlay from "./NavOverlay/NavOverlay";
import TextInputOverlay from "../ui/Input/TextInputOverlay/TextInputOverlay";
import CustomNavLink from "../ui/customNavLink/CustomNavLink";

import { clearAll, setQuotedOnly } from "../../store/productQuery";
import { b2bOrder, cartHasItemsSelector, isB2BOrderSelector, setCartOpen } from "../../store/cart";
import { isLoggedInSelector, logout } from "../../store/auth";

import { keys } from "../../locales/localeskeys";
import logo from "../../assets/svg/adfast-logo-black.svg";

import classes from "./Navbar.module.scss";
import { getItemCarts } from "../../store/product";
import { getLibraries } from "../../store/library";
import { getPageLinks } from "../../store/pageLinks";
import TextInput from "../ui/Input/TextInput/TextInput";
import SearchInputOverlay from "./SearchInputOverlay/SearchInputOverlay";

import colorMatching from "../../assets/svg/color-matching.svg";
import productCompatibility from "../../assets/svg/product-compatibility.svg";
import productSelector from "../../assets/svg/product-selector.svg";
import calculatorIcon from "../../assets/png/Calculator_icon.png";

const Navbar = ({ classes }) => {
	const dispatch = useDispatch();
	const { t, i18n } = useTranslation();
	const lngId = i18n.language;

	const navigate = useNavigate();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const [searchParams, setSearchParams] = useSearchParams();
	const searchState = searchParams.get("searchValue") ?? "";
	const isIframe = searchParams.get("iframe") ?? false;
	const iframeTitle = searchParams.get("title") ?? "";
	const iframeImg = searchParams.get("img") ?? "";

	const search = `?lng=${lngId}`;

	const isLoggedIn = useSelector(isLoggedInSelector);
	const cartHasItems = useSelector(cartHasItemsSelector);
	const isB2BOrder = useSelector(isB2BOrderSelector);

	const searchRef = useRef();

	const [activeID, setActiveID] = useState("");
	const [displayOverlay, setDisplayOverlay] = useState("");
	const [closingDrop, setCLosingDrop] = useState({ id: "", status: false });
	const [showFreeShip, setShowFreeShip] = useState(false);

	const hoverHandler = (id) => {
		if (closingDrop.status === false && closingDrop.id !== id) {
			setActiveID(id);
		}
	};

	const outHandler = () => {
		setActiveID("");
		setTimeout(
			() =>
				setCLosingDrop({
					id: "",
					status: false,
				}),
			300
		);
	};

	const closeDrop = () => {
		setCLosingDrop({ id: activeID, status: true });
		setActiveID("");
	};

	useEffect(() => {
		dispatch(b2bOrder());
	}, []);

	useEffect(() => {
		if (!isLoggedIn) {
			setShowFreeShip(false);
		} else {
			if (isB2BOrder) {
				setShowFreeShip(true);
			} else {
				setShowFreeShip(false);
			}
		}
	}, [isLoggedIn, isB2BOrder]);

	useEffect(() => {
		if (closingDrop.status === true && activeID === "")
			setCLosingDrop({
				id: closingDrop.id,
				status: false,
			});
	}, [activeID, closingDrop.status]);

	const onLocaliseChange = (item) => {
		i18n.changeLanguage(item.children);
	};

	const onLogout = () => {
		dispatch(logout());
	};

	const searchChangeHandler = (event) => {
		setSearchParams({ searchValue: event.target.value ?? "" });
	};

	const submitHandler = (event) => {
		event.preventDefault();
		if (location.pathname === "/search" || location.pathname === "/search/") {
			dispatch(
				getItemCarts({
					search_feature: true,
					search_document: false,
					keep: false,
					searchValue: searchState,
				})
			);
			dispatch(
				getLibraries({
					search: searchState,
					page: 1,
					pageSize: 16,
					keep: false,
				})
			);
			dispatch(
				getPageLinks({
					search: searchState,
					page: 1,
					pageSize: 16,
					keep: false,
					t,
				})
			);
		} else {
			navigate({
				pathname: `/search/`,
				search: `${search}${searchState ? "&searchValue=" + searchState : ""}`,
			});
		}
		setActiveID("");
	};

	const keyDownHandler = (event) => {
		if (event.key === "Escape") {
			setActiveID("");
		}
	};

	const firstHeaderCustomNavLinks = [
		{
			to: lngId.includes("fr")
				? "https://workforcenow.adp.com/mascsr/default/mdf/recruitment/recruitment.html?cid=887f8344-cc31-4562-a308-c796ba030c45&ccId=19000101_000001&lang=fr_CA"
				: "https://workforcenow.adp.com/mascsr/default/mdf/recruitment/recruitment.html?cid=887f8344-cc31-4562-a308-c796ba030c45&ccId=19000101_000001&lang=en_CA&selectedMenuKey=CareerCenter&langChange=true",
			target: "_blank",
			content: t(keys.GLOBAL.NAVBAR.JOINTEAM),
		},
		{
			to: "/aboutus",
			target: "_self",
			content: t(keys.GLOBAL.NAVBAR.ABOUT_US),
		},
		{
			to: "/faq",
			target: "_self",
			content: t(keys.GLOBAL.NAVBAR.FAQ),
		},
		{
			to: "/contact-us",
			target: "_self",
			content: <div className={classes.contactUs}>{t(keys.GLOBAL.NAVBAR.CONTACT_US)}</div>,
		},
	];

	return (
		<div className={classes.NavBar}>
			<nav>
				{!showFreeShip && !location.pathname.includes("header") && !location.pathname.includes("footer") && (
					<CustomNavLink to={`/term_privacy/`} className={classes.promo}>
						<span>{t(keys.GLOBAL.COMMON.PROMO)} FREESHIP2025</span>
					</CustomNavLink>
				)}
				<div className={classes.firstHeader}>
					<div
						id={"8"}
						className={`${classes.rli} ${classes.languages}`}
						onMouseOver={() => {
							hoverHandler("8");
						}}
						onMouseOut={outHandler}
					>
						<p className={activeID === "8" ? classes["active"] : ""}>
							{t(keys.GLOBAL.NAVBAR.LANGUE)} : {lngId.toLowerCase()}
						</p>
						<NavDrop
							left={"2rem"}
							parentId={"8"}
							activeID={activeID}
							height={"9rem"}
							width={"9.1rem"}
							hide={closeDrop}
							backgroundColor={"#333333"}
							link={classes.link}
							data={[
								{ id: "13", children: "fr-ca" },
								{ id: "14", children: "en-ca" },
								{ id: "25", children: "en-us" },
							]}
							onClick={onLocaliseChange}
						/>
					</div>
					<ul className={classes.info}>
						{firstHeaderCustomNavLinks.map((link, key) => (
							<li key={key}>
								<CustomNavLink to={link.to} target={link.target}>
									{link.content}
								</CustomNavLink>
							</li>
						))}
					</ul>
				</div>
				<div className={classes.bar}>
					<div className={classes.inner}>
						<CustomNavLink to={`/`} className={classes.left} onClick={() => setActiveID("")}>
							<img className={classes.logo_icon} src={logo} alt={logo} />
						</CustomNavLink>
						<div className={classes.center}>
							<div className={classes.top}>
								<ul>
									<form onSubmit={submitHandler}>
										<SearchInputOverlay
											ref={searchRef}
											id="search"
											label={t(keys.GLOBAL.SEARCH)}
											value={searchState}
											onChange={searchChangeHandler}
											className={classes.input}
											onSearch={submitHandler}
											onClose={() => {
												searchParams.delete("searchValue");
												setSearchParams(searchParams);
												setActiveID("");
											}}
											onKeyDown={keyDownHandler}
										/>
									</form>
									<li
										className={classes.rli}
										onClick={() =>
											isLoggedIn
												? dispatch(setCartOpen(true))
												: navigate({ pathname: `/sign-in`, search: queryParams.toString() })
										}
									>
										<Icon
											className={classes.icon}
											icon={
												cartHasItems === true
													? "bi:cart-check"
													: "ant-design:shopping-cart-outlined"
											}
										/>
									</li>
									<li
										id={"6"}
										className={classes.rli}
										onMouseOver={() => {
											hoverHandler("6");
										}}
										onMouseOut={outHandler}
									>
										<div>
											<Icon
												className={classes.icon}
												icon={isLoggedIn ? "bx:user-check" : "ant-design:user-outlined"}
											/>
										</div>
										<NavDrop
											right={"0"}
											parentId={"6"}
											activeID={activeID}
											height={isLoggedIn ? "13rem" : "10rem"}
											width={"12rem"}
											hide={closeDrop}
											data={
												isLoggedIn
													? [
															{
																id: "11",
																link: `/account${search}`,
																children: t(keys.GLOBAL.NAVBAR.MYACCOUNT),
															},
															{
																id: "26",
																link: `/adstore/${search}`,
																onClick: () => dispatch(setQuotedOnly(true)),
																children: t(keys.GLOBAL.NAVBAR.MYPRODUCTS),
															},
															{
																id: "12",
																link: `/orders${search}`,
																children: t(keys.GLOBAL.NAVBAR.ORDERS),
															},
															{
																id: "10",
																children: t(keys.AUTH.SIGN_IN.LOG_OUT),
																onClick: onLogout,
															},
													  ]
													: [
															{
																id: "23",
																link: `/sign-in${search}`,
																children: t(keys.AUTH.FORGOT_PASSWORD.LOGIN),
															},
															{
																id: "24",
																link: `/account${search}`,
																children: t(keys.GLOBAL.NAVBAR.MYACCOUNT),
															},
															{
																id: "15",
																link: `/orders${search}`,
																children: t(keys.GLOBAL.NAVBAR.ORDERS),
															},
													  ]
											}
										/>
									</li>
									<li className={classes.rli}>
										<CustomNavLink to={"/find-a-store/"} target={"_self"}>
											<Icon className={classes.icon} icon="majesticons:map-marker-area" />
										</CustomNavLink>
									</li>
									<li
										className={classes.rli}
										onClick={() =>
											isLoggedIn
												? onLogout()
												: navigate({ pathname: `/sign-in`, search: queryParams.toString() })
										}
									>
										<div className={classes.logInButton}>
											{isLoggedIn
												? t(keys.AUTH.SIGN_IN.LOG_OUT)
												: t(keys.GLOBAL.NAVBAR.SIGN_IN_SIGN_UP)}
										</div>
									</li>
								</ul>
							</div>
							<div className={classes.bottom}>
								<ul>
									<li
										className={`${classes.li}`}
										id={"1"}
										onMouseOver={() => {
											hoverHandler("1");
										}}
										onMouseOut={outHandler}
									>
										<CustomNavLink
											className={`${classes.link} ${activeID === "1" ? classes["active"] : ""}`}
											to={`/${search}`}
										>
											{t(keys.GLOBAL.NAVBAR.HOME)}
										</CustomNavLink>
										<span>|</span>
									</li>
									<li
										className={`${classes.li}`}
										id={"2"}
										onMouseOver={() => {
											hoverHandler("2");
										}}
										onMouseOut={outHandler}
									>
										<p className={`${classes.link} ${activeID === "2" ? classes["active"] : ""}`}>
											{t(keys.PRODUCTS.INDUSTRIES)}
										</p>
										<NavDrop
											left={"0"}
											parentId={"2"}
											activeID={activeID}
											height={"12rem"}
											hide={closeDrop}
											cube
											data={[
												{
													id: "1",
													link: `/construction${search}`,
													children: t(keys.GLOBAL.FOOTER.CONSTRUCTION),
													img: "https://aceafcstorageprod01.blob.core.windows.net/public-images/b2b/HomePage/Industry_Picker/Construction.png",
												},
												{
													id: "2",
													link: `/manufacturing${search}`,
													children: t(keys.GLOBAL.FOOTER.MANUFACTURERS),
													img: "https://aceafcstorageprod01.blob.core.windows.net/public-images/b2b/HomePage/Industry_Picker/Manufacturer.png",
												},
												{
													id: "3",
													link: `/architect${search}`,
													children: t(keys.GLOBAL.FOOTER.ARCHITECT),
													img: "https://aceafcstorageprod01.blob.core.windows.net/public-images/b2b/HomePage/Industry_Picker/Architect.png",
												},
											]}
										/>
										<span>|</span>
									</li>
									<li
										className={`${classes.li}`}
										id={"3"}
										onMouseOver={() => {
											hoverHandler("3");
										}}
										onMouseOut={outHandler}
									>
										<CustomNavLink
											className={`${classes.link} ${activeID === "3" ? classes["active"] : ""}`}
											to={`/adstore/${search}`}
											onClick={() => dispatch(clearAll())}
										>
											{t(keys.GLOBAL.NAVBAR.PRODUCTS)}
										</CustomNavLink>
										<NavDrop
											left={"0"}
											parentId={"3"}
											activeID={activeID}
											height={"22.5rem"}
											hide={closeDrop}
											onClick={() => dispatch(clearAll())}
											cube
											data={[
												{
													id: "19",
													link: `/adstore/${search}`,
													children: lngId.includes("en")
														? "All products"
														: "Tous les produits",
													img: "https://aceafcstorageprod01.blob.core.windows.net/public-images/b2b/HomePage/Industry_Picker/AllProducts.png",
												},
												{
													id: "4",
													link: `/adstore/${search}&brands=${["Adseal"].join(",")}`,
													children: lngId.includes("en")
														? "Adseal Sealants"
														: "Scellants Adseal",
													img: "https://aceafcstorageprod01.blob.core.windows.net/public-images/b2b/HomePage/Industry_Picker/Adseal.png",
												},
												{
													id: "5",
													link: `/adstore/${search}&brands=${["Adbond"].join(",")}`,
													children: lngId.includes("en")
														? "Adbond Adhesives"
														: "Adhésif Adbond",
													img: "https://aceafcstorageprod01.blob.core.windows.net/public-images/b2b/HomePage/Industry_Picker/Adbond.png",
												},
												{
													id: "6",
													link: `/adstore/${search}&brands=${["Adfoam"].join(",")}`,
													children: lngId.includes("en")
														? "Adfoam Insulating foam"
														: "Mousses isolantes Adfoam",
													img: "https://aceafcstorageprod01.blob.core.windows.net/public-images/b2b/HomePage/Industry_Picker/Adfoam.png",
												},
												{
													id: "17",
													link: `/adstore/${search}&brands=${["Adthane"].join(",")}`,
													children: lngId.includes("en")
														? "Adthane Polyurethanes"
														: "Polyuréthanes Adthane",
													img: "https://aceafcstorageprod01.blob.core.windows.net/public-images/b2b/HomePage/Industry_Picker/Particulars.png",
												},
												{
													id: "18",
													link: `/adstore/${search}&brands=${["Thermobond"].join(",")}`,
													children: lngId.includes("en")
														? "Thermobond Hot-melt adhesives"
														: "Colles thermofusibles Thermobond",
													img: "https://aceafcstorageprod01.blob.core.windows.net/public-images/b2b/HomePage/Industry_Picker/Thermobond.png",
												},
											]}
										/>
										<span>|</span>
									</li>
									<li
										className={`${classes.li}`}
										id={"9"}
										onMouseOver={() => {
											hoverHandler("9");
										}}
										onMouseOut={outHandler}
									>
										<p className={`${classes.link} ${activeID === "9" ? classes["active"] : ""}`}>
											{t(keys.GLOBAL.NAVBAR.AUTOMATION)}
										</p>
										<NavDrop
											left={"0"}
											parentId={"9"}
											activeID={activeID}
											height={"12rem"}
											hide={closeDrop}
											onClick={() => dispatch(clearAll())}
											cube
											data={[
												{
													id: "20",
													link: `/adrobot/${search}`,
													children: t(keys.GLOBAL.NAVBAR.ADROBOT),
													img: "https://aceafcstorageprod01.blob.core.windows.net/public-images/b2b/HomePage/Industry_Picker/Adrobot.png",
												},
												{
													id: "21",
													link: `/dispensing-technologie/${search}`,
													children: t(keys.GLOBAL.NAVBAR.DTECH),
													img: "https://aceafcstorageprod01.blob.core.windows.net/public-images/b2b/HomePage/Industry_Picker/DispensingTechnologies.png",
												},
											]}
										/>
										<span>|</span>
									</li>
									<li
										className={`${classes.li}`}
										id={"10"}
										onMouseOver={() => {
											hoverHandler("10");
										}}
										onMouseOut={outHandler}
									>
										<CustomNavLink
											className={`${classes.link} ${activeID === "10" ? classes["active"] : ""}`}
											to={`/architect/library/${search}`}
										>
											{t(keys.ARCHITECT.LIBRARYTXT)}
										</CustomNavLink>
										<span>|</span>
									</li>
									<li
										className={`${classes.li}`}
										id={"30"}
										onMouseOver={() => {
											hoverHandler("30");
										}}
										onMouseOut={outHandler}
									>
										<p className={`${classes.link} ${activeID === "30" ? classes["active"] : ""}`}>
											{t(keys.GLOBAL.NAVBAR.PARTNERS)}
										</p>
										<NavDrop
											parentId={"30"}
											activeID={activeID}
											height={"12rem"}
											hide={closeDrop}
											onClick={() => dispatch(clearAll())}
											cube
											data={[
												// {
												// 	id: "31",
												// 	link: `/jameshardie/${search}`,
												// 	children: t(keys.GLOBAL.NAVBAR.JAMESHARDI),
												// 	img: "https://aceafcstorageprod01.blob.core.windows.net/public-images/b2b/HomePage/Industry_Picker/JamesHardie.png",
												// },
												{
													id: "32",
													link: `/nichiha/${search}`,
													children: t(keys.GLOBAL.NAVBAR.NICHIHA),
													img: "https://aceafcstorageprod01.blob.core.windows.net/public-images/b2b/HomePage/Industry_Picker/Nichiha.png",
												},
												{
													id: "33",
													link: `/ceraclad/${search}`,
													children: t(keys.GLOBAL.NAVBAR.CERACLAD),
													img: "https://aceafcstorageprod01.blob.core.windows.net/public-images/b2b/HomePage/Industry_Picker/Ceraclad.png",
												},
											]}
										/>
										<span>|</span>
									</li>
									<li
										className={`${classes.li}`}
										id={"4"}
										onMouseOver={() => {
											hoverHandler("4");
										}}
										onMouseOut={outHandler}
									>
										<p className={`${classes.link} ${activeID === "4" ? classes["active"] : ""}`}>
											{t(keys.GLOBAL.NAVBAR.TOOLS)}
										</p>
										<NavDrop
											left={"-10rem"}
											parentId={"4"}
											activeID={activeID}
											height={"12.5rem"}
											width={"15rem"}
											hide={closeDrop}
											box
											data={[
												{
													id: "8",
													link: `/tools/find-the-right-sealant/${search}`,
													target: "_self",
													children: t(keys.GLOBAL.NAVBAR.COLOR_TOOL),
													img: colorMatching,
												},
												{
													id: "22",
													link: `/tools/sealant-calculator/${search}`,
													target: "_self",
													children: t(keys.GLOBAL.NAVBAR.CALCULATOR),
													img: calculatorIcon,
												},
												{
													id: "7",
													link: lngId.includes("en")
														? "https://adfastcorp.com/en/silicone-compatibility/"
														: "https://adfastcorp.com/compatibilite-silicones/",
													target: "_blank",
													children: t(keys.GLOBAL.NAVBAR.PRODUCT_COMPATIBILITY),
													img: productCompatibility,
												},
												{
													id: "9",
													link: "/tools/product-selector/",
													target: "_self",
													children: t(keys.GLOBAL.NAVBAR.GUIDED_SELECTOR),
													img: productSelector,
												},
											]}
										/>
									</li>
									<li
										className={`${classes.li}`}
										id={"35"}
										onMouseOver={() => {
											hoverHandler("35");
										}}
										onMouseOut={outHandler}
									>
										<CustomNavLink
											className={`${classes.colorMatch} ${classes.link} `}
											to={`/tools/find-the-right-sealant/${search}`}
											target={"_self"}
										>
											<img src={colorMatching} alt="tube.svg" />
											{t(keys.GLOBAL.NAVBAR.COLORMATCH)}
										</CustomNavLink>
									</li>
								</ul>
							</div>
						</div>
						<div className={classes.right}></div>
						<div className={classes.mobile_menu}>
							<div>
								<Icon
									className={classes.icon}
									onClick={() =>
										isLoggedIn
											? dispatch(setCartOpen(true))
											: navigate({ pathname: `/sign-in`, search: queryParams.toString() })
									}
									icon={cartHasItems === true ? "bi:cart-check" : "ant-design:shopping-cart-outlined"}
								/>
							</div>
							<CustomNavLink to={`/account?lng=fr-ca`}>
								<Icon
									className={classes.icon}
									icon={isLoggedIn ? "bx:user-check" : "ant-design:user-outlined"}
								/>
							</CustomNavLink>
							<div className={classes.burger}>
								<Icon
									className={classes.icon}
									onClick={() => {
										setDisplayOverlay("open");
									}}
									icon="charm:menu-hamburger"
								/>
								<NavOverlay
									onClose={() => {
										setDisplayOverlay("");
									}}
									onOpen={displayOverlay}
								/>
							</div>
						</div>
					</div>
					<form onSubmit={submitHandler}>
						<div className={classes.searchLayout}>
							<TextInput
								className={classes.searchInput}
								placeholder={t(keys.SEARCH.SEARCH)}
								onChange={searchChangeHandler}
								search={submitHandler}
								onKeyDown={keyDownHandler}
								value={searchState}
							/>
						</div>
					</form>
				</div>
			</nav>
			<SideCart />
			{isIframe && (
				<div className={classes.iframeTitle} style={{ height: "500px", backgroundImage: `url(${iframeImg})` }}>
					<p>{iframeTitle}</p>
				</div>
			)}
		</div>
	);
};

Navbar.defaultProps = {
	classes,
};

export default Navbar;

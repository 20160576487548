import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { keys } from "../../../locales/localeskeys";

import { PO_REFERENCE_STEP, DELIVERY_MODE_STEP, useCheckout } from "../../../providers";

import { updateOrder } from "../../../store/cart";

import { TextInput, Button } from "../../../atoms";

import { CardWrapper } from "../../../commons";

export const POReference = () => {
	const dispatch = useDispatch();

	const { t } = useTranslation();

	const { classes, order, currentStep, setCurrentStep, handleOnNextStep, isEditable } = useCheckout();

	const status = useSelector((state) => state.cart.statusUpdateOrder);

	const isStepAccessible = useMemo(
		() => currentStep === PO_REFERENCE_STEP || isEditable(PO_REFERENCE_STEP),
		[currentStep]
	);

	const isDisabled = useMemo(() => currentStep !== PO_REFERENCE_STEP && isEditable(PO_REFERENCE_STEP), [currentStep]);

	const isLoading = useMemo(() => status === "loading" && currentStep === PO_REFERENCE_STEP, [status, currentStep]);

	const handleOnSave = (e) => {
		e.preventDefault();

		dispatch(
			updateOrder({
				purchase_order_ref: e.target.elements.purchase_order_ref.value.trim(),
				customer_ref: e.target.elements.customer_ref.value.trim(),
				step: PO_REFERENCE_STEP,
			})
		);
	};

	useEffect(() => {
		if (status === "succeeded" && currentStep === PO_REFERENCE_STEP) {
			handleOnNextStep(DELIVERY_MODE_STEP);
		}
	}, [status]);

	return (
		<CardWrapper
			classNameWrapper={classes.checkout_card}
			header={
				<>
					<div className={classes.label}>{t(keys.CHECKOUT.MAIN.PURCHASE_ORDER.TITLE)}</div>
					{isEditable(PO_REFERENCE_STEP) && (
						<div className={classes.textlink} onClick={() => setCurrentStep(PO_REFERENCE_STEP)}>
							{t(keys.CHECKOUT.MAIN.COMMONS.EDIT)}
						</div>
					)}
				</>
			}
		>
			{isStepAccessible && (
				<form onSubmit={handleOnSave}>
					<TextInput
						label={t(keys.CHECKOUT.MAIN.PURCHASE_ORDER.PO_REFERENCE)}
						name="purchase_order_ref"
						value={order.purchase_order_ref || ""}
						required={true}
						disabled={isDisabled}
						inputProps={{maxLength: 20}}
						caption={t(keys.CHECKOUT.MAIN.PURCHASE_ORDER.PO_REFERENCE_CAPTION)}
					/>
					<TextInput
						label={t(keys.CHECKOUT.MAIN.PURCHASE_ORDER.FULLNAME)}
						name="customer_ref"
						value={order.customer_ref || ""}
						required={true}
						disabled={isDisabled}
						inputProps={{maxLength: 60}}
						caption={t(keys.CHECKOUT.MAIN.PURCHASE_ORDER.FULLNAME_CAPTION)}
					/>
					{!isDisabled && (
						<Button type="submit" loading={isLoading}>
							{t(keys.CHECKOUT.MAIN.COMMONS.CONTINUE)}
						</Button>
					)}
				</form>
			)}
		</CardWrapper>
	);
};

import { Icon } from '@iconify/react';
import React from 'react';

import classes from "./Spinner.module.scss";

export const Spinner = ({classes}) => {
    return <Icon icon="ei:spinner-3" width="24" height="24" className={classes.spinner} />
}

Spinner.defaultProps = {
    classes,
}
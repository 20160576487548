import React from "react";

import classes from "./EmptyState.module.scss";

import emptyBox from "../../assets/svg/empty-box.svg";

import { Button } from "../../atoms";

export const EmptyState = ({ classes, imageAlt, title, messages, onClick, buttonLabel }) => {
	return (
		<div className={classes.emptyState}>
			<div>
				<img src={emptyBox} alt={imageAlt} />
			</div>
			<div className={classes.title}>{title}</div>
			<div>
				{messages.map((message, key) => (
					<p key={key}>{message}</p>
				))}
			</div>
			<Button onClick={onClick}>{buttonLabel}</Button>
		</div>
	);
};

EmptyState.defaultProps = {
	classes,
	imageAlt: "",
	title: "",
	messages: [],
	onClick: () => {},
	buttonLabel: "",
};

import classes from "../ProductListInfo/ProductListInfo.module.scss";
import Button from "../../../ui/Button/Button";
import { keys } from "../../../../locales/localeskeys";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearQuestion } from "../../../../store/productSelector";

const ProductListInfo = ({
	classes,
	features_documents,
	product_features,
	image,
	title_fr,
	title_en,
	label_fr,
	label_en,
	text_fr,
	text_en,
	gridWidth,
	erp_code,
	brandErpCode,
}) => {
	const { t, i18n } = useTranslation();
	const lngId = i18n.language;
	const dispatch = useDispatch();
	const location = useLocation();

	const navigate = useNavigate();

	const [featuresRender, setFeaturesRender] = useState([]);
	const [showAll, setShowAll] = useState(false);

	useEffect(() => {
		setFeaturesRender(
			features_documents
				? features_documents?.filter((feature) => feature.language.includes(lngId.substring(0, 2)))
				: []
		);
	}, [lngId, features_documents]);

	useEffect(() => {
		dispatch(clearQuestion());
	}, [location.pathname]);

	const handleSeeMoreClick = () => {
		setShowAll(true);
	};

	const productRedirect = () => {
		dispatch(clearQuestion());
		navigate(`/adstore/overview/${erp_code}/${brandErpCode ? brandErpCode : ""}`);
	};

	return (
		<div className={`${classes.productInfoSection} `}>
			<div className={`${classes.productDisplay}`}>
				<div className={classes.imageContainer} style={{ cursor: "default" }}>
					<div className={classes.contain}>
						<img className={classes.overViewImg} src={image} alt="product-display.svg" />
					</div>
				</div>
				<Button className={classes.viewDetail} name="view-detail-mobile" onClick={productRedirect}>
					{t(keys.PRODUCTS.VIEWDETAIL)}
				</Button>
			</div>
			<div className={`${classes.productInfoContainer}`}>
				<div className={classes.titleContainer}>
					<h1>{lngId.includes("fr") && title_fr ? title_fr : title_en}</h1>
					{(lngId.includes("fr") ? label_fr : label_en) && (
						<h2>{lngId.includes("fr") ? label_fr : label_en}</h2>
					)}
					{(lngId.includes("fr") ? text_fr : text_en) && <p>{lngId.includes("fr") ? text_fr : text_en}</p>}
				</div>
				<div className={`${classes.productDisplayMobile}`}>
					<div className={classes.imageContainerMobile} style={{ cursor: "default" }}>
						<div className={classes.containMobile}>
							<img className={classes.overViewImgMobile} src={image} alt="product-display.svg" />
						</div>
					</div>
					<Button className={classes.viewDetailMobile} name="view-detail-web" onClick={productRedirect}>
						{t(keys.PRODUCTS.VIEWDETAIL)}
					</Button>
				</div>
				<div className={classes.featuresContainer}>
					<hr />
					<h3>{t(keys.PRODUCTS.OVERVIEW.FEATURES)}</h3>
					{gridWidth > 1068 ? (
						<ul>
							{product_features.length > 0
								? product_features.map((feature) => (
										<li key={feature.id}>
											{lngId.includes("fr") ? feature.text_fr : feature.text_en}
										</li>
								  ))
								: featuresRender.map((feature) => <li key={feature.id}>{feature.text}</li>)}
						</ul>
					) : (
						<>
							<ul>
								{showAll
									? product_features.length > 0
										? product_features.map((feature) => (
												<li key={feature.id}>
													{lngId.includes("fr") ? feature.text_fr : feature.text_en}
												</li>
										  ))
										: featuresRender.map((feature) => <li key={feature.id}>{feature.text}</li>)
									: (product_features.length > 0
											? product_features.slice(0, 4)
											: featuresRender.slice(0, 4)
									  ).map((feature) => (
											<li key={feature.id}>
												{lngId.includes("fr") ? feature.text_fr : feature.text_en}
											</li>
									  ))}
							</ul>
							{(product_features.length > 0 ? product_features.length : featuresRender.length) > 4 &&
								!showAll && (
									<span className={classes.more} onClick={handleSeeMoreClick}>
										{t(keys.PRODUCTS.VIEWMORE)}
									</span>
								)}
						</>
					)}
				</div>
			</div>
		</div>
	);
};
ProductListInfo.defaultProps = {
	classes,
};

export default ProductListInfo;

import React from "react";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";

export const SearchSelectDropdown = React.forwardRef(
	({ label, name, required, options, optionLabel, value, onChange, InputLabelProps, className, rootClass }, ref) => {
		const handleOnChange = (_, value) => {
			onChange(value);
		};

		return (
			<div className={className}>
				<Autocomplete
					ref={ref}
					options={options}
					getOptionLabel={(option) => option?.[optionLabel] || ""}
					value={value}
					onChange={handleOnChange}
					classes={{
						root: rootClass,
					}}
					renderInput={(params) => (
						<TextField
							{...params}
							label={label}
							name={name}
							required={required}
							InputLabelProps={InputLabelProps}
						/>
					)}
				/>
			</div>
		);
	}
);

SearchSelectDropdown.defaultProps = {
	label: "",
	name: "",
	required: false,
	options: [],
	optionLabel: "label",
	value: null,
	onChange: () => {},
	InputLabelProps: {},
};

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { parse, isBefore, differenceInMonths } from "date-fns";

import { keys } from "../../../../locales/localeskeys";

import {
	paymentMethodsSelector,
	setDefaultPaymentMethod,
	setPaymentMethod,
	deletePaymentMethod,
} from "../../../../store/paymentMethod";
import { usePaymentMethod } from "../../../../hooks";

import { Button } from "../../../../atoms/button/Button";
import Card from "../../card/Card";

import classes from "./PaymentMethodsList.module.scss";

export const PaymentMethodsList = ({ classes }) => {
	const dispatch = useDispatch();

	const { t } = useTranslation();
	const { extendedPaymentMethodInfo } = usePaymentMethod(t);
	const paymentMethods = useSelector(paymentMethodsSelector);
	const [availablePaymentMethods, setAvailablePaymentMethods] = useState([]);

	const navigate = useNavigate();

	const isExpirationNear = (expireAtString) => {
		const now = new Date();
		const expireAt = parse(expireAtString, "MM/yyyy", new Date());

		return isBefore(expireAt, now) || differenceInMonths(expireAt, now) <= 3;
	};

	const handleAddPaymentMethod = () => {
		dispatch(setPaymentMethod(null));
		navigate("/account/payment-method");
	};

	const handleSetByDefault = (paymentMethod) => {
		window.scrollTo(0, 0);
		dispatch(setDefaultPaymentMethod(paymentMethod.id));
	};

	const handleOnEdit = (paymentMethod) => {
		dispatch(setPaymentMethod(paymentMethod.id));
		navigate("/account/payment-method");
	}

	const handleOnRemove = (paymentMethod) => {
		dispatch(deletePaymentMethod(paymentMethod.id));
	};

	const sortPaymentMethods = (paymentMethods) => {
		return [...paymentMethods].sort((a, _) => (a.is_default ? -1 : 1));
	};

	useEffect(() => {
		if (paymentMethods) {
			setAvailablePaymentMethods(sortPaymentMethods(paymentMethods));
			window.scrollTo(0, 0);
		}
	}, [paymentMethods]);

	return (
		<div className={classes.frameset}>
			<div className={classes.header}>
				<Button onClick={handleAddPaymentMethod} icon="material-symbols:add">
					{t(keys.ACCOUNT.PAYMENT_METHODS.ADD_BUTTON_LABEL)}
				</Button>
			</div>
			<div className={classes.content}>
				{availablePaymentMethods.map((data, key) => {
					const nearExpiration = isExpirationNear(data.expires_at);
					const showSetByDefault = isBefore(new Date(), parse(data.expires_at, "MM/yyyy", new Date()));
					const { label, description } = extendedPaymentMethodInfo(data)

					return (
						<Card
							key={key}
							title={label}
							isDefault={data.is_default}
							onChange={() => handleSetByDefault(data)}
							onEdit={() => handleOnEdit(data)}
							onRemove={() => handleOnRemove(data)}
							showSetByDefault={showSetByDefault}
							showEdit={true}
    						showRemove={!data.is_default}
						>
							<div>{description}</div>
							<div className={nearExpiration ? classes.nearExpiration : ""}>
								{nearExpiration && (
									<Icon className={classes.nearExpiration} icon="material-symbols:warning" />
								)}
								{`${t(keys.ACCOUNT.PAYMENT_METHODS.EXPIRE_ON)} ${data.expires_at}`}
							</div>
						</Card>
					);
				})}
			</div>
		</div>
	);
};

PaymentMethodsList.defaultProps = {
	classes,
};

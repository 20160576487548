import React, { createContext, useContext, useState, useMemo, useEffect } from "react";

const CheckoutContext = createContext();

export const PO_REFERENCE_STEP = "purchase-order-reference";
export const DELIVERY_MODE_STEP = "delivery-mode";
export const PAYMENT_METHOD_STEP = "payment-method";
export const PLACE_ORDER_STEP = "place-order";

export const CheckoutProvider = ({
	children,
	classes,
	order,
	isAvailableCreditCard,
	isAvailableShippingFees,
	orderLines,
	taxPercent,
}) => {
	const [steps, setSteps] = useState(order?.steps ? order.steps : []);
	const [currentStep, setCurrentStep] = useState(PO_REFERENCE_STEP);
	const [shippingFees, setShippingFees] = useState();

	const subTotal = useMemo(() => orderLines.reduce((total, line) => total + Number(line.amount), 0), [orderLines]);
	const shippingFeesAmount = (isAvailableShippingFees && shippingFees ? shippingFees : 0);
	const taxAmount = ((subTotal + shippingFeesAmount) * taxPercent) / 100;

	const total = subTotal + shippingFeesAmount + taxAmount;

	const handleOnNextStep = (step) => {
		if (!steps.includes(currentStep)) {
			setSteps((prev) => [...prev, currentStep]);
		}

		setCurrentStep(!steps.includes(step) ? step : PLACE_ORDER_STEP);
	};

	const isEditable = (step) => steps.includes(step);

	useEffect(() => {
		if(order?.steps) {
			if(order.steps.includes(PO_REFERENCE_STEP)) setCurrentStep(DELIVERY_MODE_STEP);

			if(order.steps.includes(DELIVERY_MODE_STEP)) setCurrentStep(isAvailableCreditCard ? PAYMENT_METHOD_STEP : PLACE_ORDER_STEP);

			if(order.steps.includes(PAYMENT_METHOD_STEP)) setCurrentStep(PLACE_ORDER_STEP);

			if(order.steps.includes(PLACE_ORDER_STEP)) setCurrentStep(PLACE_ORDER_STEP);
		}
	}, []);

	return (
		<CheckoutContext.Provider
			value={{
				classes,
				order,
				isAvailableCreditCard,
				isAvailableShippingFees,
				orderLines,
				subTotal,
				taxAmount,
				total,
				currentStep,
				setCurrentStep,
				shippingFees,
				setShippingFees,
				handleOnNextStep,
				isEditable,
			}}
		>
			{children}
		</CheckoutContext.Provider>
	);
};

export const useCheckout = () => useContext(CheckoutContext);

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import { TextField, InputAdornment } from "@material-ui/core";
import { Icon } from "@iconify/react";

import fr from "date-fns/locale/fr";
import en from "date-fns/locale/en-US";

import "react-datepicker/dist/react-datepicker.css";
import classes from "./DateTimePicker.module.scss";

export const DateTimePicker = React.forwardRef(
	(
		{ classes, label, name, required, minDate, dateFormat, value, inputProps, filterDate, filterTime, disabled },
		ref
	) => {
		const { i18n } = useTranslation();

		const [selectedDate, setSelectedDate] = useState(value);

		return (
			<DatePicker
				wrapperClassName={classes.root}
				ref={ref}
				{...inputProps}
				name={name}
				required={required}
				disabled={disabled}
				selected={selectedDate}
				minDate={minDate}
				locale={i18n.language.includes("fr") ? fr : en}
				dateFormat={dateFormat}
				onChange={(date) => setSelectedDate(date)}
				filterDate={filterDate}
				filterTime={filterTime}
				customInput={
					<TextField
						className={classes.datepickerInput}
						label={label}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<Icon icon="tdesign:calendar-filled" />
								</InputAdornment>
							),
						}}
						inputProps={{
							readOnly: true,
						}}
					/>
				}
			/>
		);
	}
);

DateTimePicker.defaultProps = {
	classes,
};

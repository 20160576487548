import React from "react";
import { useTranslation } from "react-i18next";

import { keys } from "../../../locales/localeskeys";

import { CardWrapper, ListCardItem } from "../../../commons";

import { useCheckout } from "../../../providers";

import OrderLine from "./orderLine/OrderLine";

export const OrderLines = () => {
	const { t } = useTranslation();

	const { classes, orderLines } = useCheckout();

	return (
		<CardWrapper
			classNameWrapper={classes.checkout_card}
			header={<div className={classes.label}>{t(keys.CART.OVERVIEW.ORDER_LINES.TITLE)}</div>}
		>
			<div className={classes.bold}>
				{t(keys.CART.OVERVIEW.ORDER_LINES.DELIVERY)}: {orderLines.length}{" "}
				{t(keys.CART.OVERVIEW.ORDER_LINES.ITEM)}
			</div>

			<ListCardItem
				classNameWrapper={classes.checkout_list_item}
				items={orderLines.map((orderLine) => <OrderLine key={orderLine.id} classes={classes} orderLine={orderLine}/>)}
			/>
		</CardWrapper>
	);
};

import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

import { getDay, setHours, setMinutes, parse, isAfter } from "date-fns";
import fr from "date-fns/locale/fr";
import en from "date-fns/locale/en-US";

import { keys } from "../../../locales/localeskeys";

import {
	DELIVERY_MODE_STEP,
	FormActionsProvider,
	PAYMENT_METHOD_STEP,
	PLACE_ORDER_STEP,
	useCheckout,
} from "../../../providers";

import { useOrderLine } from "../../../hooks";

import { getShippingFees, shippingFeesSelector, updateOrder, errorSelector } from "../../../store/cart";
import {
	shippingAddressesSelector,
	deleteShippingAddress,
	setShippingAddress,
	shippingAddressSelector,
} from "../../../store/shippingAddress";

import { DateTimePicker, RadioInput, TextInput, Button, Alert } from "../../../atoms";
import Loading from "../../ui/loading/Loading";

import { CardWrapper, ListCardItem, Modal } from "../../../commons";
import { ShippingAddressForm } from "../../account/shippingAddress/shippingAddressForm/ShippingAddressForm";

export const DeliveryMode = () => {
	const dispatch = useDispatch();

	const { t, i18n } = useTranslation();
	const lngId = i18n.language;

	const {
		classes,
		order,
		isAvailableCreditCard,
		isAvailableShippingFees,
		currentStep,
		setCurrentStep,
		handleOnNextStep,
		isEditable,
		setShippingFees,
	} = useCheckout();
	const { formatCurrency } = useOrderLine(t, lngId);

	const status = useSelector((state) => state.cart.statusUpdateOrder);
	const statusShippingAddress = useSelector((state) => state.cart.status);
	const error = useSelector(errorSelector);

	const DELIVERY_MODE_DELIVERY = "is_delivery";
	const DELIVERY_MODE_PICKUP = "is_pickup";

	const [deliveryMode, setDeliveryMode] = useState(order?.is_pickup ? DELIVERY_MODE_PICKUP : DELIVERY_MODE_DELIVERY);
	const [shippingAddressSelected, setShippingAddressSelected] = useState(order?.address || {});
	const [shippingAddressModal, setShippingAddressModal] = useState(false);

	const shippingAddresses = useSelector(shippingAddressesSelector);
	const shippingAddress = useSelector(shippingAddressSelector);
	const shippingFees = useSelector(shippingFeesSelector);

	const isStepAccessible = useMemo(
		() => currentStep === DELIVERY_MODE_STEP || isEditable(DELIVERY_MODE_STEP),
		[currentStep]
	);

	const isDisabled = useMemo(
		() => currentStep !== DELIVERY_MODE_STEP && isEditable(DELIVERY_MODE_STEP),
		[currentStep]
	);

	const isLoading = useMemo(() => status === "loading" && currentStep === DELIVERY_MODE_STEP, [status, currentStep]);

	const isLoadingShippingFees = useMemo(
		() => statusShippingAddress === "loading" && currentStep === DELIVERY_MODE_STEP,
		[statusShippingAddress]
	);

	const dateFormat = useMemo(
		() => (deliveryMode === DELIVERY_MODE_PICKUP ? "MMMM d, yyyy h:mm aa" : "MMMM d, yyyy"),
		[deliveryMode]
	);

	const currentDate = useMemo(() => {
		const order_date = parse(order.order_date, dateFormat, new Date(), {
			locale: lngId.startsWith("fr") ? fr : en,
		});

		let now = new Date();
		now.setDate(now.getDate() + 1);
		now = setHours(now, 6);
		now = setMinutes(now, 0);

		if (order?.order_date && isAfter(order_date, now)) now = order_date;

		if (now.getDay() === 6) now.setDate(now.getDate() + 2);
		if (now.getDay() === 0) now.setDate(now.getDate() + 1);

		return now;
	}, [order]);

	const processedShippingAddresses = useMemo(
		() =>
			shippingAddresses
				.map((address) => {
					const shippingFeesAmount = shippingFees.filter((item) => item.address_id == address.id);
					return {
						...address,
						shippingFees: shippingFeesAmount[0]?.amount || 0,
					};
				})
				.sort((a, b) => {
					if (a.is_default === b.is_default) {
						return a.name.localeCompare(b.name);
					}
					return a.is_default ? -1 : 1;
				}),
		[shippingFees]
	);

	const handleShippingAddressModal = {
		open: () => setShippingAddressModal(true),
		close: () => setShippingAddressModal(false),
	};

	const isWeekday = (date) => {
		const day = getDay(date);
		return day !== 0 && day !== 6;
	};

	const filterPassedTime = (time) => {
		const hour = time.getHours();
		return hour >= 6 && hour < 17;
	};

	const handleOnSave = (e) => {
		e.preventDefault();

		let deliveryInformation = {};
		if (deliveryMode === DELIVERY_MODE_DELIVERY) {
			deliveryInformation = {
				address_id: shippingAddressSelected.id,
				delivery_line1: shippingAddressSelected.line1,
				delivery_line2: shippingAddressSelected.line2,
				delivery_city: shippingAddressSelected.city,
				delivery_postal_code: shippingAddressSelected.postal_code,
				delivery_state: shippingAddressSelected.state,
				delivery_country: shippingAddressSelected.country,
				delivery_name: shippingAddressSelected.name,
				delivery_phone: shippingAddressSelected.phone,
			};

			setShippingFees(shippingAddressSelected.shippingFees);
		} else {
			setShippingFees();
		}

		dispatch(
			updateOrder({
				...deliveryInformation,
				is_pickup: deliveryMode === DELIVERY_MODE_PICKUP,
				customer_note: e.target.elements.customer_note.value.trim(),
				order_date: parse(e.target.elements.order_date.value, dateFormat, new Date(), {
					locale: lngId.startsWith("fr") ? fr : en,
				}),
				step: DELIVERY_MODE_STEP,
			})
		);
	};

	const handleOnEdit = (address) => {
		dispatch(setShippingAddress(address.id));
		setShippingAddressModal(true);
	};

	const handleOnRemove = (address) => {
		dispatch(deleteShippingAddress(address.id));
	};

	useEffect(() => {
		const defaultShippingAddress = shippingAddresses.filter((address) => address.is_default);
		if (defaultShippingAddress.length > 0 && !order?.address) {
			setShippingAddressSelected(defaultShippingAddress[0]);
		}

		dispatch(getShippingFees());
	}, [shippingAddresses]);

	useEffect(() => {
		if (status === "succeeded" && currentStep === DELIVERY_MODE_STEP) {
			handleOnNextStep(isAvailableCreditCard ? PAYMENT_METHOD_STEP : PLACE_ORDER_STEP);
		}
	}, [status]);

	return (
		<>
			<CardWrapper
				classNameWrapper={classes.checkout_card}
				header={
					<>
						<div className={classes.label}>{t(keys.CHECKOUT.MAIN.DELIVERY_MODE.TITLE)}</div>
						{isEditable(DELIVERY_MODE_STEP) && (
							<div className={classes.textlink} onClick={() => setCurrentStep(DELIVERY_MODE_STEP)}>
								{t(keys.CHECKOUT.MAIN.COMMONS.EDIT)}
							</div>
						)}
					</>
				}
			>
				{isStepAccessible && (
					<form onSubmit={handleOnSave}>
						{error && currentStep === DELIVERY_MODE_STEP && (
							<Alert severity="error">{t(keys.CHECKOUT.ERRORS[error])}</Alert>
						)}
						<div>
							<div className={classes.bold}>{t(keys.CHECKOUT.MAIN.DELIVERY_MODE.DLV_MODE)}</div>
							<RadioInput
								label={t(keys.CHECKOUT.MAIN.DELIVERY_MODE.DELIVERY_LABEL)}
								name="delivery_mode"
								value={DELIVERY_MODE_DELIVERY}
								checked={deliveryMode === DELIVERY_MODE_DELIVERY}
								onChange={() => setDeliveryMode(DELIVERY_MODE_DELIVERY)}
								disabled={isDisabled}
							/>
							<RadioInput
								label={t(keys.CHECKOUT.MAIN.DELIVERY_MODE.PICKUP_LABEL)}
								name="delivery_mode"
								value={DELIVERY_MODE_PICKUP}
								checked={deliveryMode === DELIVERY_MODE_PICKUP}
								onChange={() => {
									setDeliveryMode(DELIVERY_MODE_PICKUP);
									setShippingAddress();
								}}
								disabled={isDisabled}
							/>
						</div>

						<div className={classes.bold}>
							{t(
								deliveryMode === DELIVERY_MODE_PICKUP
									? keys.CHECKOUT.MAIN.DELIVERY_MODE.SELECTED_MODE.PICKUP_LABEL
									: keys.CHECKOUT.MAIN.DELIVERY_MODE.SELECTED_MODE.DELIVERY_LABEL
							)}
						</div>

						{deliveryMode == DELIVERY_MODE_DELIVERY && (
							<>
								{isLoadingShippingFees && <Loading type="linear" />}
								{!isLoadingShippingFees && (
									<ListCardItem
										classNameWrapper={classes.checkout_list_item}
										items={processedShippingAddresses.map((address) => (
											<>
												<div>
													<RadioInput
														name="shipping_address"
														value={address.id}
														checked={shippingAddressSelected.id === address.id}
														onChange={() => setShippingAddressSelected(address)}
														required={true}
														disabled={isDisabled}
													/>
												</div>
												<div className={classes.content}>
													<div>{address.name}</div>
													<div>{`${address.line1}, ${address.city}, ${address.state}, ${address.postal_code}, ${address.country}`}</div>
													{isAvailableShippingFees && (
														<div className="caption">
															{`${t(
																keys.CHECKOUT.SUMMARY.SHIPPING_FEES
															)}: ${formatCurrency(address.shippingFees)}`}
														</div>
													)}
													{!address.is_default && !isDisabled && (
														<div className={classes.align_right}>
															<div
																className={classes.textlink}
																onClick={() => handleOnEdit(address)}
															>
																{t(keys.CHECKOUT.MAIN.COMMONS.EDIT)}
															</div>
															<div
																className={clsx(classes.textlink, classes.red)}
																onClick={() => handleOnRemove(address)}
															>
																{t(keys.CHECKOUT.MAIN.COMMONS.REMOVE)}
															</div>
														</div>
													)}
												</div>
											</>
										))}
									/>
								)}

								{!isDisabled && (
									<>
										<div
											className={clsx(classes.textlink, classes.align_right)}
											onClick={handleShippingAddressModal.open}
										>
											{t(keys.CHECKOUT.MAIN.DELIVERY_MODE.ADD_SHIPPING_ADDRESS)}
										</div>

										{isAvailableShippingFees && (
											<Alert severity="info">
												{t(keys.CHECKOUT.MAIN.DELIVERY_MODE.DELIVERY_ALERT)}
											</Alert>
										)}
									</>
								)}
							</>
						)}

						<TextInput
							label={t(keys.CHECKOUT.MAIN.DELIVERY_MODE.NOTE)}
							name="customer_note"
							value={order.customer_note || ""}
							disabled={isDisabled}
						/>

						<DateTimePicker
							label={t(keys.CHECKOUT.MAIN.DELIVERY_MODE.DLV_DATE)}
							inputProps={{
								showDisabledMonthNavigation: true,
								showTimeSelect: deliveryMode === DELIVERY_MODE_PICKUP,
							}}
							filterDate={isWeekday}
							filterTime={filterPassedTime}
							name="order_date"
							value={currentDate}
							minDate={currentDate}
							dateFormat={dateFormat}
							required={true}
							disabled={isDisabled}
						/>

						{!isDisabled && (
							<Button type="submit" loading={isLoading}>
								{t(keys.CHECKOUT.MAIN.COMMONS.CONTINUE)}
							</Button>
						)}
					</form>
				)}
			</CardWrapper>

			{shippingAddressModal && (
				<Modal
					header={
						shippingAddress.id
							? t(keys.CHECKOUT.MAIN.DELIVERY_MODE.EDIT_SHIPPING_ADDRESS)
							: t(keys.CHECKOUT.MAIN.DELIVERY_MODE.ADD_SHIPPING_ADDRESS)
					}
					onClose={handleShippingAddressModal.close}
				>
					<FormActionsProvider callback={handleShippingAddressModal.close}>
						<ShippingAddressForm />
					</FormActionsProvider>
				</Modal>
			)}
		</>
	);
};

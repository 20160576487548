import React, { useMemo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

import { keys } from "../../../locales/localeskeys";

import { PLACE_ORDER_STEP, useCheckout } from "../../../providers";
import { useOrderLine } from "../../../hooks";

import { updateOrder } from "../../../store/cart";

import { Button, Alert } from "../../../atoms";

import { CardWrapper, Modal } from "../../../commons";
import { PurchaseSuccess } from "../PurchaseSuccess";

export const Summary = () => {
	const dispatch = useDispatch();

	const { t, i18n } = useTranslation();
	const lngId = i18n.language;

	const { classes, subTotal, taxAmount, shippingFees, total, currentStep, isAvailableShippingFees } =
		useCheckout();
	const { formatCurrency } = useOrderLine(t, lngId);

	const status = useSelector((state) => state.cart.statusUpdateOrder);

	const isDisabled = useMemo(() => currentStep !== PLACE_ORDER_STEP, [currentStep]);

	const isLoading = useMemo(() => status === "loading" && currentStep === PLACE_ORDER_STEP, [status, currentStep]);

	const [summaryModal, setSummaryModal] = useState(false);

	const handleOnSave = () => {
		dispatch(
			updateOrder({
				is_processed: true,
				step: PLACE_ORDER_STEP,
			})
		);
	};

	useEffect(() => {
		if (status === "succeeded" && currentStep === PLACE_ORDER_STEP) {
			setSummaryModal(true);
		}
	}, [status]);

	return (
		<>
			<CardWrapper
				classNameWrapper={classes.checkout_card}
				header={<div className={classes.label}>{t(keys.CHECKOUT.SUMMARY.TITLE)}</div>}
			>
				<div className={classes.checkout_detail}>
					<div>{t(keys.CHECKOUT.SUMMARY.SUBTOTAL)}</div>
					<div className={classes.align_right}>{formatCurrency(subTotal)}</div>
					<div>{t(keys.CHECKOUT.SUMMARY.TAX)}</div>
					<div className={classes.align_right}>{formatCurrency(taxAmount)}</div>
					{isAvailableShippingFees && shippingFees != undefined && (
						<>
							<div>{t(keys.CHECKOUT.SUMMARY.SHIPPING_FEES)}</div>
							<div className={classes.align_right}>{formatCurrency(shippingFees)}</div>
						</>
					)}
					{!isAvailableShippingFees && (
						<div className={classes.checkout_alert}>
							<Alert severity="warning">{t(keys.CHECKOUT.SUMMARY.SHIPPING_FEES_ALERT)}</Alert>
						</div>
					)}
					<div className={clsx(classes.bold, classes.checkout_total)}>{t(keys.CHECKOUT.SUMMARY.TOTAL)}</div>
					<div className={clsx(classes.bold, classes.checkout_total, classes.align_right)}>
						{formatCurrency(total)}
					</div>
				</div>
				{isAvailableShippingFees && <Alert severity="info">{t(keys.CHECKOUT.SUMMARY.REVIEW)}</Alert>}
				<Button type="submit" loading={isLoading && !isDisabled} onClick={handleOnSave} disabled={isDisabled}>
					{t(keys.CHECKOUT.SUMMARY.ORDER)}
				</Button>
			</CardWrapper>

			{summaryModal && (
				<Modal header={t(keys.CHECKOUT.SUMMARY.SUCCESS_TITLE)} allowCloseModal={false}>
					<PurchaseSuccess />
				</Modal>
			)}
		</>
	);
};

import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import classes from "./ProductSelector.module.scss";

import { useDispatch, useSelector } from "react-redux";
import LinkTree from "../ui/LinkTree/LinkTree";
import { keys } from "../../locales/localeskeys";
import Dropdown from "../ui/Input/Dropdown/Dropdown";
import { clearQuestion, getQuestion, questionIsLoadingSelector, questionSelector } from "../../store/productSelector";
import Loading from "../ui/loading/Loading";
import ProductListInfo from "../products/ProductOverview/ProductListInfo/ProductListInfo";
import useElementSize from "../../hooks/useElementSize";

const ProductSelector = ({ classes }) => {
	const { t, i18n } = useTranslation();
	const lngId = i18n.language;
	const dispatch = useDispatch();
	const loadingdRef = useRef();
	const [gridRef, { width: gridWidth }] = useElementSize();

	const question = useSelector(questionSelector);
	const questionIsLoading = useSelector(questionIsLoadingSelector);

	const [questionsList, setQuestionsList] = useState([]);

	const questionChangeHandler = (event, questionIndex) => {
		let selectedValue;
		if (event.target) {
			selectedValue = event.target.value;
		} else if (event?.next_question) {
			selectedValue = event.next_question;
		}
		if (selectedValue !== "default") {
			setQuestionsList((prevQuestions) => {
				const updatedQuestions = [...prevQuestions];
				updatedQuestions[questionIndex] = {
					...updatedQuestions[questionIndex],
					associated_responses: updatedQuestions[questionIndex].associated_responses.map((response) => ({
						...response,
						selected: response?.next_question === selectedValue,
					})),
				};
				return updatedQuestions.slice(0, questionIndex + 1);
			});
			if (selectedValue !== "") {
				dispatch(getQuestion({ question_id: selectedValue }));
			}
		}
	};

	useEffect(() => {
		setQuestionsList([]);
		dispatch(clearQuestion());
		dispatch(getQuestion({ is_head: true }));
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (question && question.length > 0) {
			const newQuestions = question.filter(
				(q) => !questionsList.some((existingQuestion) => existingQuestion.id === q.id)
			);
			if (newQuestions.length > 0) {
				setQuestionsList((prevList) => [...prevList, ...newQuestions]);
			}
		}
	}, [question]);

	useEffect(() => {
		if (questionIsLoading) {
			if (loadingdRef.current) {
				loadingdRef.current.scrollIntoView({ behavior: "smooth" });
			}
		}
	}, [loadingdRef, questionIsLoading]);

	return (
		<div className={classes.layout} ref={gridRef}>
			<LinkTree
				tree={[
					{ label: t(keys.GLOBAL.NAVBAR.HOME), to: "/" },
					{ label: t(keys.TOOLS.TITLE), to: "/" },
					{ label: "Product Selector", to: `/tools/product-selector/` },
				]}
				className={classes.Tree}
			/>
			<h1 className={classes.title}>{t(keys.PRODUCT_SELECTOR_PAGE.BODY.TITLE)}</h1>
			<div className={classes.description}>
				<p>{t(keys.PRODUCT_SELECTOR_PAGE.BODY.DESCRIPTION)}</p>
			</div>

			{questionsList.map((question, index) => (
				<div key={question.id}>
					<div className={classes.dimensions}>
						<h2>{lngId.includes("fr") ? question.question_fr : question.question_en}</h2>
						{question?.associated_responses &&
							question?.associated_responses.length > 0 &&
							question.associated_responses.every((response) => response.item_detail === null) && (
								<>
									<Dropdown
										className={classes.inputJoint}
										wrapperClassName={classes.after}
										onChange={(e) => questionChangeHandler(e, index)}
										value={
											question.associated_responses.find((response) => response.selected)
												?.next_question || "default"
										}
										name="question_Type"
									>
										<option value="default">
											{t(keys.PRODUCT_SELECTOR_PAGE.BODY.DEFAUT_DROPDOWN)}
										</option>
										{question?.associated_responses.map((response) => (
											<option
												key={response.id}
												value={response.next_question ? response.next_question : ""}
											>
												{lngId.includes("fr") ? response.response_fr : response.response_en}
											</option>
										))}
									</Dropdown>
									<div className={classes.segment}>
										{question?.associated_responses
											.slice()
											.sort((a, b) =>
												lngId.includes("fr")
													? a.response_fr.localeCompare(b.response_fr)
													: a.response_en.localeCompare(b.response_en)
											)
											.map((response) => (
												<div
													className={`${classes.segmentPicker} ${
														response.selected ? classes.selectedCard : ""
													}`}
													key={response.id}
													onClick={() => questionChangeHandler(response, index)}
												>
													<div className={classes.segmentPickerCard}>
														<img
															src={
																response?.response_img
																	? response?.response_img
																	: "https://adfastcorpcdnak.azureedge.net/b2b/Product_Pictures/Noimage_b2b.png"
															}
															alt="response"
														/>
														<div className={classes.segmentPickerCardTitle}>
															<p>
																{lngId.includes("fr")
																	? response.response_fr
																	: response.response_en}
															</p>
														</div>
													</div>
												</div>
											))}
									</div>
								</>
							)}
					</div>
					<div>
						{question?.associated_responses?.map(
							(response) =>
								response?.item_detail && (
									<div className={classes.productDetail} key={response.item_detail.id}>
										<ProductListInfo
											features_documents={response.item_detail?.features_documents}
											product_features={response.product_features}
											image={
												response.item_detail?.pictures.length > 0
													? response.item_detail?.pictures[0]
													: response.item_detail?.defaultImage
											}
											title_fr={response.item_detail?.title_fr}
											title_en={response.item_detail?.title_en}
											label_fr={response.item_detail?.label_fr}
											label_en={response.item_detail?.label_en}
											text_fr={response.item_detail?.text_fr}
											text_en={response.item_detail?.text_en}
											gridWidth={gridWidth}
											erp_code={response.item_detail?.erp_code}
											brandErpCode={response.item_detail?.brandErpCode}
										/>
									</div>
								)
						)}
					</div>
				</div>
			))}
			{questionIsLoading && (
				<div ref={loadingdRef}>
					<Loading className={classes.loading} type={"place"} />
				</div>
			)}
		</div>
	);
};

ProductSelector.defaultProps = {
	classes,
};

export default ProductSelector;

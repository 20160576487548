import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import fr from "date-fns/locale/fr";
import en from "date-fns/locale/en-US";

import { CardWrapper, ListCardItem } from "../../../../commons";
import { Button, Alert } from "../../../../atoms";
import OrderLine from "./orderLine/OrderLine";
import ReportLink from "./reportLink/ReportLink";

import { orderConfirmationSelector, orderInvoiceSelector, orderPackingSelector } from "../../../../store/report";
import { taxPercentSelector } from "../../../../store/auth";
import { copyOrder, isLoadingSelector } from "../../../../store/cart";

import { keys } from "../../../../locales/localeskeys";

import classes from "./OrderItem.module.scss";

const OrderItem = ({ classes, report }) => {
	const dispatch = useDispatch();

	const { t, i18n } = useTranslation();
	const lngId = i18n.language;

	const [total, setTotal] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const taxPercent = useSelector(taxPercentSelector);
	const orderConfigurations = useSelector((state) => orderConfirmationSelector(state, report.id));
	const orderPackings = useSelector((state) => orderPackingSelector(state, report.id));
	const orderInvoices = useSelector((state) => orderInvoiceSelector(state, report.id));
	const loadingStatus = useSelector(isLoadingSelector);

	const getOrderStatusText = () => {
		if (!report.erp_code) {
			return t(keys.ACCOUNT.ORDERS.PENDING);
		}

		switch (report.status) {
			case 0:
				return t(keys.ACCOUNT.ORDERS.OPEN);
			case 1:
				return t(keys.ACCOUNT.ORDERS.CONFIRMED);
			case 2:
				return t(keys.ACCOUNT.ORDERS.DELIVERED);
			case 3:
				return t(keys.ACCOUNT.ORDERS.INVOICED);
			default:
				return t(keys.ACCOUNT.ORDERS.CANCELED);
		}
	};

	const formatCurrency = (amount) => {
		const amountStr = Number(amount)
			.toFixed(2)
			.toString()
			.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		return lngId.includes("fr") ? `${amountStr.replace(".", ",")}$` : `$${amountStr}`;
	};

	const handleCopyOrder = (orderId) => {
		dispatch(copyOrder(orderId));
		setIsLoading(true);
	};

	const isDisabled = useMemo(
		() => report.orderline.map((orderLine) => !orderLine.variant?.prices?.length).some((hasPrices) => hasPrices),
		[report]
	);

	useEffect(() => {
		if (isLoading === true && !loadingStatus) {
			setIsLoading(false);
		}
	}, [loadingStatus]);

	useEffect(() => {
		if (report && report.orderline && report.orderline.length > 0) {
			const subAmount = report.orderline
				.map((line) => Number(line.amount))
				.reduce((prev, curr) => prev + curr, 0);
			const taxAmount = (subAmount * taxPercent) / 100;
			const amount = subAmount + taxAmount;
			setTotal(amount);
		}
	}, [report, taxPercent]);

	return (
		<CardWrapper
			classNameWrapper={classes.order_card}
			header={
				<>
					<div>
						<label>{t(keys.ACCOUNT.ORDERS.SALESORDER)}</label>
						<p>{report.erp_code || "---"}</p>
					</div>
					<div>
						<label>{t(keys.ACCOUNT.ORDERS.STATUS)}</label>
						<p>{getOrderStatusText()}</p>
					</div>
					<div>
						<label>{t(keys.ACCOUNT.ORDERS.PURCHASEORDER)}</label>
						<p>{report?.purchase_order_ref}</p>
					</div>
					<div>
						<label>{t(keys.ACCOUNT.ORDERS.ORDERDATE)}</label>
						<p>
							{format(new Date(report.order_date), "dd MMM yyyy", {
								locale: lngId.includes("fr") ? fr : en,
							})}
						</p>
					</div>
					<div>
						<label>{t(keys.ACCOUNT.ORDERS.TOTAL)}</label>
						<p>{formatCurrency(total)}</p>
					</div>
				</>
			}
		>
			{report.orderline.length > 0 ? (
				<ListCardItem
					items={report.orderline.map((line) => (
						<OrderLine key={line.id} line={line} />
					))}
				/>
			) : (
				<Alert severity="warning">{t(keys.ACCOUNT.ORDERS.NO_ORDER_LINES)}</Alert>
			)}

			<div className={classes.options}>
				<div className={classes.reports}>
					{orderConfigurations && orderConfigurations.length > 0 && (
						<ReportLink items={orderConfigurations} label={t(keys.ACCOUNT.ORDERS.VIEWCS)} />
					)}
					{orderPackings && orderPackings.length > 0 && (
						<ReportLink items={orderPackings} label={t(keys.ACCOUNT.ORDERS.VIEWPS)} />
					)}
					{orderInvoices && orderInvoices.length > 0 && (
						<ReportLink items={orderInvoices} label={t(keys.ACCOUNT.ORDERS.VIEWIS)} />
					)}
				</div>
				<Button
					icon="mdi:reload"
					onClick={() => handleCopyOrder(report.id)}
					disabled={isDisabled}
					loading={isLoading}
				>
					{t(keys.ACCOUNT.ORDERS.BUYALLAGAIN)}
				</Button>
			</div>
		</CardWrapper>
	);
};

OrderItem.defaultProps = {
	classes,
};

export default OrderItem;

import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { keys } from "../../locales/localeskeys";
import { constants } from "../../locales/constant";

import { isCreditCardEnabled, isShippingFeesEnabled, isLoggedInSelector, setPrecedentUrl, taxPercentSelector } from "../../store/auth";
import { orderLinesSelector, orderSelector } from "../../store/cart";

import { HeaderPage } from "../../commons";

import { CheckoutProvider } from "../../providers";

import { POReference, DeliveryMode, PaymentMethod, Cart, Summary } from "./sections";

import classes from "./Checkout.module.scss";

const Checkout = ({ classes }) => {
	const dispatch = useDispatch();

	const { t, i18n } = useTranslation();
	const lngId = i18n.language;

	const isLoggedIn = useSelector(isLoggedInSelector);
	const order = useSelector(orderSelector);
	const orderLines = useSelector(orderLinesSelector);
	const isAvailableCreditCard = useSelector(isCreditCardEnabled);
	const isAvailableShippingFees = useSelector(isShippingFeesEnabled);
	const taxPercent = useSelector(taxPercentSelector);

	const navigate = useNavigate();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	const handleCallToPhone = () => {
		window.open(`tel:${constants.CHECKOUT_PHONENUMBER}`);
	};

	useEffect(() => {
		if (!isLoggedIn) {
			dispatch(setPrecedentUrl({ pathname: location.pathname, search: location.search }));
			navigate({ pathname: `/sign-in`, search: queryParams.toString() });
		} else {
			if(!order.id)
				navigate({ pathname: `/`, search: queryParams.toString() });
		}
	}, [isLoggedIn]);

	return (
		<section className="pages-layout">
			<HeaderPage
				tree={[
					{ label: t(keys.GLOBAL.NAVBAR.HOME), to: `/?lng=${lngId}` },
					{ label: t(keys.CART.OVERVIEW.TITLE), to: `/cart?lng=${lngId}` },
					{ label: t(keys.CHECKOUT.MAIN.TITLE), to: `/checkout?lng=${lngId}` },
				]}
				title={t(keys.CHECKOUT.MAIN.TITLE)}
			/>

			<main>
				<article>
					{t(keys.CHECKOUT.MAIN.ASSISTANCE)}{" "}
					<span className={classes.textlink} onClick={handleCallToPhone}>
						{constants.CHECKOUT_PHONENUMBER}
					</span>
				</article>

				<article className={classes.checkout}>
					<CheckoutProvider
						classes={classes}
						order={order}
						isAvailableCreditCard={isAvailableCreditCard}
						isAvailableShippingFees={isAvailableShippingFees}
						orderLines={orderLines}
						taxPercent={taxPercent}
					>
						<div className={classes.cards}>
							<POReference />
							<DeliveryMode />
							{isAvailableCreditCard && <PaymentMethod />}
							<Summary />
						</div>
						<div className={classes.cards}>
							<Cart />
						</div>
					</CheckoutProvider>
				</article>
			</main>
		</section>
	);
};

Checkout.defaultProps = {
	classes,
};

export default Checkout;

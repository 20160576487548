import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { constants as constant } from "../locales/constant";

export const getQuestion = createAsyncThunk(
	"question/getQuestion",
	async ({ is_head = false, question_id = "" }, { rejectWithValue }) => {
		try {
			const queryParam =
				constant.API_SERVER +
				"/questionResponses/" +
				(is_head && is_head !== false ? "?is_head=" + is_head : "") +
				(question_id && question_id !== "" ? "?question_id=" + question_id : "");

			const response = await fetch(queryParam);
			if (!response.ok) {
				throw new Error("fetching questions failed");
			}
			return await response.json();
		} catch (err) {
			return rejectWithValue(err.message, err);
		}
	}
);

const questionAdapter = createEntityAdapter({});

const question = createSlice({
	name: "question",
	initialState: questionAdapter.getInitialState({
		loading: "done",
		currentRequestId: undefined,
		error: undefined,
	}),
	reducers: {
		clearQuestion: (state) => {
			questionAdapter.removeAll(state);
			state.loading = "done";
			state.currentRequestId = undefined;
			state.error = undefined;
		},
	},
	extraReducers: {
		[getQuestion.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = undefined;
				questionAdapter.setAll(state, payload);
			}
			return state;
		},
		[getQuestion.pending]: (state, { meta }) => {
			if (!state.loading || state.loading === "done") {
				state.currentRequestId = meta.requestId;
				state.loading = "pending";
				state.error = undefined;
			}
			return state;
		},
		[getQuestion.rejected]: (state, { meta, payload }) => {
			if (state.loading === "pending" && state.currentRequestId === meta.requestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = payload;
			}
			return state;
		},
	},
});

export default question;
export const { clearQuestion } = question.actions;
export const questionSelectors = questionAdapter.getSelectors((state) => state.question);
export const questionSelector = createSelector(questionSelectors.selectAll, (stores) => stores);
export const questionIsLoadingSelector = (state) => state?.question?.loading === "pending";

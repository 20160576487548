import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

import { keys } from "../../../../../locales/localeskeys";

import { Button } from "../../../../../atoms/button/Button";
import CustomNavLink from "../../../../ui/customNavLink/CustomNavLink";

import { useLocalized, useOrderLine } from "../../../../../hooks";

import { copyLine, isLoadingSelector } from "../../../../../store/cart";

import classes from "./OrderLine.module.scss";

const OrderLine = ({ classes, line }) => {
	const dispatch = useDispatch();

	const { t, i18n } = useTranslation();
	const lngId = i18n.language;

	const { processedOrderLine, formatCurrency, formatPackaging } = useOrderLine(t, lngId);

	const { id, brand, item, image, label, amount, specification, format, variant, qty, unitAmount } =
		processedOrderLine(line);
	const { packagingFormat, displayFormat } = formatPackaging(format, variant, true);

	const { getLocalizedValueByKeyType } = useLocalized(i18n.language);

	const search = `?lng=${lngId}`;

	const [isLoading, setIsLoading] = useState(false);

	const loadingStatus = useSelector(isLoadingSelector);

	const isDisabled = useMemo(() => !variant?.prices?.length, [variant]);

	const handleCopyLine = () => {
		dispatch(copyLine(id));
		setIsLoading(true);
	};

	useEffect(() => {
		if (isLoading === true && !loadingStatus) {
			setIsLoading(false);
		}
	}, [loadingStatus]);

	return (
		<>
			<div className={classes.product}>
				<CustomNavLink
					to={
						brand
							? `/adstore/overview/${item.erp_code}/${brand?.erp_code}/${search}`
							: `/adstore/overview/${item.erp_code}/${search}`
					}
				>
					<img className={classes.productImage} src={image} alt={label} />
				</CustomNavLink>

				<div className={classes.info}>
					<CustomNavLink
						to={
							brand
								? `/adstore/overview/${item.erp_code}/${brand?.erp_code}/${search}`
								: `/adstore/overview/${item.erp_code}/${search}`
						}
					>
						<div className={classes.name}>{label}</div>
					</CustomNavLink>
					<div className={`${classes.number} ${classes.subtitle}`}>{formatCurrency(amount)}</div>
					{specification?.erp_code && specification?.hex && (
						<a
							href={
								brand && specification && format
									? `/adstore/variants/${item?.erp_code}/${brand?.erp_code}/${specification?.erp_code}/${format?.erp_code}/${search}`
									: `/adstore/overview/${item?.erp_code}/${search}`
							}
						>
							{`${specification?.erp_code} ${getLocalizedValueByKeyType(specification, "description")}`}
						</a>
					)}
					<div className={clsx(classes.number, "caption")}>{`${qty} x ${formatCurrency(unitAmount)}`}</div>
					{unitAmount && (format?.description_fr || format?.description_en) && (
						<div className={classes.full_width}>
							{formatCurrency(unitAmount)} / {getLocalizedValueByKeyType(format, "description")}
						</div>
					)}
					{displayFormat && (
						<div className={classes.full_width}>{`${variant?.on_hand[0]?.multiple} ${
							qty > 1 ? t(keys.GLOBAL.COMMON.UNITS) : t(keys.GLOBAL.COMMON.UNIT)
						} ${t(keys.GLOBAL.COMMON.PER)} ${packagingFormat}`}</div>
					)}
					<div className={classes.full_width}>
						<Button
							color="outlined"
							onClick={() => handleCopyLine()}
							disabled={isDisabled}
							loading={isLoading}
						>
							{t(keys.ACCOUNT.ORDERS.BUYAGAIN)}
						</Button>
					</div>
				</div>
			</div>
		</>
	);
};

OrderLine.defaultProps = {
	classes,
};

export default OrderLine;

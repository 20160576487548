import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { constants } from "../locales/constant";

export const setDefaultPaymentMethod = createAsyncThunk(
	"paymentMethod/setDefaultPaymentMethod",
	async (paymentMethodId, { _, getState, rejectWithValue }) => {
		try {
			const {
				auth: { access_token },
			} = getState();

			const header = new Headers();

			header.append("Authorization", "Bearer " + access_token);
			header.append("Content-Type", "application/json");

			const response = await fetch(`${constants.API_SERVER}/paymentMethod/${paymentMethodId}/`, {
				method: "PATCH",
				headers: header,
				body: JSON.stringify({ is_default: true }),
			});

			if (!response.ok) {
				const errors = await response.json();
				throw new Error(JSON.stringify(errors));
			}

			return await response.json();
		} catch (err) {
			return rejectWithValue(err.message, err);
		}
	}
);

export const addPaymentMethod = createAsyncThunk(
	"paymentMethod/addPaymentMethod",
	async (paymentMethod, { _, getState, rejectWithValue }) => {
		try {
			const {
				auth: { access_token },
			} = getState();

			const header = new Headers();

			header.append("Authorization", "Bearer " + access_token);
			header.append("Content-Type", "application/json");

			const response = await fetch(`${constants.API_SERVER}/paymentMethod/`, {
				method: "POST",
				headers: header,
				body: JSON.stringify(paymentMethod),
			});

			if (!response.ok) {
				const error = await response.json();
				throw new Error(JSON.stringify(error));
			}

			return await response.json();
		} catch (err) {
			return rejectWithValue(err.message, err);
		}
	}
);

export const updatePaymentMethod = createAsyncThunk(
	"paymentMethod/updatePaymentMethod",
	async (paymentMethod, { _, getState, rejectWithValue }) => {
		try {
			const {
				auth: { access_token },
			} = getState();

			const header = new Headers();

			header.append("Authorization", "Bearer " + access_token);
			header.append("Content-Type", "application/json");

			const response = await fetch(`${constants.API_SERVER}/paymentMethod/${paymentMethod.id}/`, {
				method: "PATCH",
				headers: header,
				body: JSON.stringify(paymentMethod),
			});

			if (!response.ok) {
				const errors = await response.json();
				throw new Error(JSON.stringify(errors));
			}

			return await response.json();
		} catch (err) {
			return rejectWithValue(err.message, err);
		}
	}
);

export const deletePaymentMethod = createAsyncThunk(
	"paymentMethod/deletePaymentMethod",
	async (paymentMethodId, { _, getState, rejectWithValue }) => {
		try {
			const {
				auth: { access_token },
			} = getState();

			const header = new Headers();

			header.append("Authorization", "Bearer " + access_token);
			header.append("Content-Type", "application/json");

			const response = await fetch(`${constants.API_SERVER}/paymentMethod/${paymentMethodId}/`, {
				method: "DELETE",
				headers: header,
			});

			if (!response.ok) {
				const errors = await response.json();
				throw new Error(JSON.stringify(errors));
			}

			return paymentMethodId;
		} catch (err) {
			return rejectWithValue(err.message, err);
		}
	}
);

const updateDefaultStatus = (state, payload) => {
	if (payload.is_default) {
		const entities = Object.values(state.entities);
		entities.forEach((entity) => {
			if (entity && entity.id !== payload.id) {
				paymentMethodsAdapter.updateOne(state, { id: entity.id, changes: { is_default: false } });
			}
		});
	}
};

const paymentMethodsAdapter = createEntityAdapter({});

const paymentMethods = createSlice({
	name: "paymentMethods",
	initialState: paymentMethodsAdapter.getInitialState({
		currentRequestId: undefined,
		error: undefined,
		status: undefined,
		paymentMethodId: null,
	}),
	reducers: {
		resetStatus(state, _) {
			state.status = undefined;
			state.error = undefined;
			state.paymentMethodId = null;
		},
		setPaymentMethod(state, { payload }) {
			state.paymentMethodId = payload;
		},
		setPaymentMethods(state, { payload }) {
			paymentMethodsAdapter.setAll(state, payload || []);
		},
	},
	extraReducers: {
		[addPaymentMethod.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestId) {
				state.currentRequestId = undefined;
				state.status = "succeeded";
				state.error = undefined;

				updateDefaultStatus(state, payload);
				paymentMethodsAdapter.addOne(state, payload);
			}
			return state;
		},
		[addPaymentMethod.pending]: (state, { meta }) => {
			state.currentRequestId = meta.requestId;
			state.status = "loading";
			state.error = undefined;

			return state;
		},
		[addPaymentMethod.rejected]: (state, { meta, payload }) => {
			if (state.status === "loading" && state.currentRequestId === meta.requestId) {
				state.currentRequestId = undefined;
				state.status = "error";
				state.error = JSON.parse(payload);
			}
			return state;
		},
		[updatePaymentMethod.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestId) {
				state.currentRequestId = undefined;
				state.status = "succeeded";
				state.error = undefined;

				updateDefaultStatus(state, payload);

				paymentMethodsAdapter.updateOne(state, { id: payload.id, changes: { ...payload } });
			}
			return state;
		},
		[updatePaymentMethod.pending]: (state, { meta }) => {
			state.currentRequestId = meta.requestId;
			state.status = "loading";
			state.error = undefined;

			return state;
		},
		[updatePaymentMethod.rejected]: (state, { meta, payload }) => {
			if (state.status.updatePaymentMethod === "loading" && state.currentRequestId === meta.requestId) {
				state.currentRequestId = undefined;
				state.status = "error";
				state.error = JSON.parse(payload);
			}
			return state;
		},
		[deletePaymentMethod.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestId) {
				state.currentRequestId = undefined;
				state.status = "succeeded";
				state.error = undefined;
				paymentMethodsAdapter.removeOne(state, payload);
			}
			return state;
		},
		[deletePaymentMethod.pending]: (state, { meta }) => {
			state.currentRequestId = meta.requestId;
			state.status = "loading";
			state.error = undefined;

			return state;
		},
		[deletePaymentMethod.rejected]: (state, { meta, payload }) => {
			if (state.status === "loading" && state.currentRequestId === meta.requestId) {
				state.currentRequestId = undefined;
				state.status = "error";
				state.error = JSON.parse(payload);
			}
			return state;
		},
		[setDefaultPaymentMethod.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestId) {
				state.currentRequestId = undefined;
				state.status = "succeeded";
				state.error = undefined;

				updateDefaultStatus(state, payload);

				paymentMethodsAdapter.updateOne(state, { id: payload.id, changes: { ...payload } });
			}
			return state;
		},
		[setDefaultPaymentMethod.pending]: (state, { meta }) => {
			state.currentRequestId = meta.requestId;
			state.status = "loading";
			state.error = undefined;

			return state;
		},
		[setDefaultPaymentMethod.rejected]: (state, { meta, payload }) => {
			if (state.status.status === "loading" && state.currentRequestId === meta.requestId) {
				state.currentRequestId = undefined;
				state.status = "error";
				state.error = JSON.parse(payload);
			}
			return state;
		},
	},
});

export const { resetStatus, setPaymentMethod, setPaymentMethods } = paymentMethods.actions;
export default paymentMethods;

const paymentMethodSelectors = paymentMethodsAdapter.getSelectors((state) => state.paymentMethods);

export const paymentMethodsSelector = createSelector(
	paymentMethodSelectors.selectAll,
	(paymentMethods) => paymentMethods
);

export const paymentMethodSelector = createSelector(
	[(state) => state.paymentMethods.paymentMethodId, paymentMethodSelectors.selectEntities],
	(selectedId, entities) => {
		if (!selectedId) return {};

		const paymentMethod = entities[selectedId];

		if (!paymentMethod) return {};

		const country = constants.COUNTRIES.find((c) => c.id === paymentMethod.country);
		const province = country?.states.find((p) => p.id === paymentMethod.state);

		return {
			...paymentMethod,
			country,
			state: province,
		};
	}
);

export const errorSelector = (state) => state.paymentMethods.error;

import React from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import { keys } from "../../../locales/localeskeys";

import { useCheckout } from "../../../providers";
import { useLocalized, useOrderLine } from "../../../hooks";

import { CardWrapper, ListCardItem } from "../../../commons";
import CustomNavLink from "../../ui/customNavLink/CustomNavLink";

export const Cart = () => {
	const { t, i18n } = useTranslation();
	const lngId = i18n.language;

	const { classes, orderLines } = useCheckout();
    const { getLocalizedValueByKeyType } = useLocalized(lngId);
	const { processedOrderLine, formatCurrency, formatPackaging } = useOrderLine(t, lngId);

	const search = `?lng=${lngId}`;

	return (
		<CardWrapper
			classNameWrapper={classes.checkout_card}
			header={<div className={classes.label}>{t(keys.CHECKOUT.CART.TITLE)}</div>}
		>
			<div className={classes.bold}>
				{t(keys.CHECKOUT.CART.DELIVERY)}: {orderLines.length} {t(keys.CHECKOUT.CART.ITEM)}
			</div>
			<ListCardItem
				classNameWrapper={classes.checkout_list_item}
				items={orderLines.map((orderLine) => {
					const { brand, item, image, label, amount, specification, format, variant, qty, unitAmount } = processedOrderLine(orderLine);
                    const { inStock, packagingFormat } = formatPackaging(format, variant);

					return (
						<>
							<div>
								<CustomNavLink
									to={
										brand
											? `/adstore/overview/${item.erp_code}/${brand?.erp_code}/${search}`
											: `/adstore/overview/${item.erp_code}/${search}`
									}
								>
									<img src={image} alt={label} />
								</CustomNavLink>
							</div>
							<div className={classes.content}>
								<CustomNavLink
									to={
										brand
											? `/adstore/overview/${item.erp_code}/${brand?.erp_code}/${search}`
											: `/adstore/overview/${item.erp_code}/${search}`
									}
								>
									<div className={classes.textlink}>{label}</div>
								</CustomNavLink>
								<div className={clsx(classes.align_right, classes.bold)}>{formatCurrency(amount)}</div>
								<div>
									{specification?.erp_code && specification?.hex && (
										<a
											href={
												brand && specification && format
													? `/adstore/variants/${item?.erp_code}/${brand?.erp_code}/${specification?.erp_code}/${format?.erp_code}/${search}`
													: `/adstore/overview/${item?.erp_code}/${search}`
											}
										>
											{`${specification?.erp_code} ${getLocalizedValueByKeyType(
												specification,
												"description"
											)}`}
										</a>
									)}
								</div>
                                <div className={clsx(classes.align_right, "caption")}>{`${qty} x ${formatCurrency(unitAmount)}`}</div>
                                {unitAmount && (format?.description_fr || format?.description_en) && (
                                    <div className={classes.full_width}>
                                        <span className={classes.bold}>{t(keys.CHECKOUT.CART.FORMAT)}: </span>
                                        <span>{getLocalizedValueByKeyType(format, "description")}</span>
                                    </div>
                                )}
                                <div className={clsx(classes.full_width, "caption", classes.green)}>{inStock} {packagingFormat} {t(keys.CART.OVERVIEW.AVAILABLE)}</div>
							</div>
						</>
					);
				})}
			/>
		</CardWrapper>
	);
};

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { constants } from "../../../locales/constant";

import { Skeleton } from "../../../atoms";

import classes from "./ProductImage.module.scss";

export const ProductImage = ({ classes, src, alt, isWMH, onClick }) => {
	const { i18n } = useTranslation();

	const [isLoading, setIsLoading] = useState(true);

	const wellMadeHereURL =
		"https://adfastcorpcdnak.azureedge.net/b2b/HomePage/Associations/BienFaitIci_WellMadeHere_Logo.png";

	const handleOnLoad = () => setIsLoading(false);

	const handleOnError = (e) => {
		e.currentTarget.src = constants.DEFAULT_IMG;
	};

	return (
		<div className={classes.container} onClick={onClick}>
			{isLoading && (
				<Skeleton variant="rect" width="100%">
					<img src={src} width="100%" />
				</Skeleton>
			)}

			<img
				className={classes.picture}
				src={src}
				alt={alt}
				onLoad={handleOnLoad}
				onError={handleOnError}
				style={{
					display: isLoading ? "none" : "block",
				}}
			/>
			{isWMH && i18n.language.endsWith("ca") && (
				<img
					className={classes.wmh}
					src={wellMadeHereURL}
					alt="BIEN FAIT ICI / WELL MADE HERE"
					style={{
						display: isLoading ? "none" : "block",
					}}
				/>
			)}
		</div>
	);
};

ProductImage.defaultProps = {
	classes,
	src: "",
	isWMH: false,
	onClick: () => {},
};

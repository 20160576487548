import React, { useState, useRef, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";

import { keys } from "../../../../locales/localeskeys";
import { constants } from "../../../../locales/constant";

import {
	addPaymentMethod,
	paymentMethodSelector,
	resetStatus,
	updatePaymentMethod,
	errorSelector,
} from "../../../../store/paymentMethod";

import { useFormActions } from "../../../../providers";

import { useLocalized } from "../../../../hooks";

import { Button, Alert, SearchSelectDropdown, CheckboxInput, TextInput, DateTimePicker } from "../../../../atoms";

import classes from "./PaymentMethodsForm.module.scss";

export const PaymentMethodsForm = ({ classes }) => {
	const dispatch = useDispatch();

	const { t, i18n } = useTranslation();
	const { getLocalizedValueByKeyType, getLocalizedValueByCustomMapping } = useLocalized(i18n.language);

	const navigate = useNavigate();

	const status = useSelector((state) => state.paymentMethods.status);
	const paymentMethod = useSelector(paymentMethodSelector);
	const error = useSelector(errorSelector);

	const [country, setCountry] = useState(paymentMethod?.country || {});
	const [state, setState] = useState(paymentMethod?.state || {});
	const [states, setStates] = useState([]);

	const zipCodeRef = useRef();

	const minMonth = useMemo(() => {
		return new Date();
	}, []);

	const expiresAt = useMemo(() => {
		if (paymentMethod.expires_at) {
			const [month, year] = paymentMethod.expires_at.split("/");
			return new Date(Date.UTC(year, month, 1));
		}
	}, [paymentMethod]);

	const { handleOnCallback } = useFormActions(() => navigate("/account/payment-methods"));

	const handleUppercaseChange = (e) => {
		if (zipCodeRef.current) {
			zipCodeRef.current.value = e.target.value.toUpperCase();
		}
	};

	const handleCountry = (value) => {
		setCountry(value);
		setState({});
	};

	const handleOnSave = (e) => {
		e.preventDefault();

		if (!paymentMethod.id) {
			dispatch(
				addPaymentMethod({
					card_number: e.target.elements.card_number.value,
					cardholder: e.target.elements.cardholder.value,
					expires_at: e.target.elements.expires_at.value,
					cvv: e.target.elements.cvv.value,
					is_default: e.target.elements.is_default.checked,
					street_address: e.target.elements.street_address.value,
					zip_code: e.target.elements.zip_code.value,
					country: country.id,
					state: state.id,
					city: e.target.elements.city.value,
					unit: e.target.elements.unit.value,
					phone_number: e.target.elements.phone_number.value,
				})
			);
		} else {
			dispatch(
				updatePaymentMethod({
					id: paymentMethod.id,
					expires_at: e.target.elements.expires_at.value,
					is_default: e.target.elements.is_default.checked,
					street_address: e.target.elements.street_address.value,
					zip_code: e.target.elements.zip_code.value,
					country: country.id,
					state: state.id,
					city: e.target.elements.city.value,
					unit: e.target.elements.unit.value,
					phone_number: e.target.elements.phone_number.value,
				})
			);
		}
	};

	const handleOnCancel = () => {
		dispatch(resetStatus());
		handleOnCallback();
	};

	useEffect(() => {
		if (country?.states) {
			setStates(country.states);
		}
	}, [country]);

	useEffect(() => {
		if (status === "succeeded") {
			dispatch(resetStatus());
			handleOnCallback();
		}
	}, [status]);

	return (
		<form onSubmit={handleOnSave}>
			<div className={classes.frameset}>
				<Alert severity="info">{t(keys.ACCOUNT.PAYMENT_METHODS.FORM.ALERT)}</Alert>
				{error?.error_code && (
					<Alert severity="error">{getLocalizedValueByKeyType(error, "description")}</Alert>
				)}
				<div className={classes.section}>
					<div className={clsx(classes.bold, classes.article)}>
						{t(keys.ACCOUNT.PAYMENT_METHODS.FORM.CARD_INFORMATION)}
					</div>
					<div className={classes.article}>
						<div className={classes.item}>
							<TextInput
								label={t(keys.ACCOUNT.PAYMENT_METHODS.FORM.CARD_NUMBER)}
								name="card_number"
								value={paymentMethod.card_number || ""}
								required={true}
								disabled={paymentMethod.id != undefined}
								pattern="[0-9]{13,18}"
								inputProps={{ maxLength: 18 }}
							/>
						</div>
					</div>
					<div className={classes.article}>
						<div className={classes.item}>
							<TextInput
								label={t(keys.ACCOUNT.PAYMENT_METHODS.FORM.CARDHOLDER)}
								name="cardholder"
								value={paymentMethod.cardholder || ""}
								required={true}
								disabled={paymentMethod.id != undefined}
								inputProps={{ maxLength: 250 }}
							/>
						</div>
						<div className={classes.item}>
							<DateTimePicker
								name="expires_at"
								minDate={minMonth}
								dateFormat="MM/yyyy"
								inputProps={{ showMonthYearPicker: true }}
								label={t(keys.ACCOUNT.PAYMENT_METHODS.FORM.EXPIRATION_DATE)}
								required={true}
								value={expiresAt || ""}
							/>
						</div>
						<div className={classes.item}>
							<TextInput
								label={t(keys.ACCOUNT.PAYMENT_METHODS.FORM.CVV)}
								name="cvv"
								required={true}
								disabled={paymentMethod.id != undefined}
								pattern="[0-9]{3,4}"
								inputProps={{ maxLength: 4 }}
							/>
						</div>
					</div>
					<div className={classes.article}>
						<CheckboxInput
							label={t(keys.ACCOUNT.CARD.SET_BY_DEFAULT)}
							name="is_default"
							value={true}
							checked={paymentMethod.is_default}
						/>
					</div>
				</div>
				<div className={classes.section}>
					<div className={clsx(classes.bold, classes.article)}>
						{t(keys.ACCOUNT.PAYMENT_METHODS.FORM.BILLING_INFORMATION)}
					</div>
					<div className={classes.article}>
						<div className={classes.item}>
							<TextInput
								label={t(keys.ACCOUNT.PAYMENT_METHODS.FORM.STREET_ADDRESS)}
								name="street_address"
								required={true}
								value={paymentMethod.street_address || ""}
								inputProps={{ maxLength: 250 }}
							/>
						</div>
						<div className={classes.item}>
							<TextInput
								label={t(keys.ACCOUNT.PAYMENT_METHODS.FORM.ZIP_CODE)}
								name="zip_code"
								required={true}
								pattern="(\d{5}(-?\d{4})?|[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d)"
								onChange={handleUppercaseChange}
								ref={zipCodeRef}
								value={paymentMethod.zip_code || ""}
								inputProps={{ maxLength: 10 }}
								caption={getLocalizedValueByCustomMapping(
									{ example_us: "ex.: 12345", example_ca: "ex.: A1A1A1" },
									{ en_us: "example_us", default: "example_ca" }
								)}
							/>
						</div>
					</div>
					<div className={classes.article}>
						<div className={classes.item}>
							<SearchSelectDropdown
								options={constants.COUNTRIES}
								optionLabel="name"
								required={true}
								label={t(keys.ACCOUNT.PAYMENT_METHODS.FORM.COUNTRY)}
								name="country"
								value={country}
								onChange={handleCountry}
							/>
						</div>
						<div className={classes.item}>
							<SearchSelectDropdown
								options={states}
								optionLabel="name"
								required={true}
								label={t(keys.ACCOUNT.PAYMENT_METHODS.FORM.STATE)}
								name="state"
								value={state}
								onChange={(value) => setState(value)}
							/>
						</div>
						<div className={classes.item}>
							<TextInput
								label={t(keys.ACCOUNT.PAYMENT_METHODS.FORM.CITY)}
								name="city"
								required={true}
								value={paymentMethod.city || ""}
								inputProps={{ maxLength: 60 }}
							/>
						</div>
					</div>
					<div className={classes.article}>
						<div className={classes.item}>
							<TextInput
								label={t(keys.ACCOUNT.PAYMENT_METHODS.FORM.UNIT)}
								name="unit"
								value={paymentMethod.unit || ""}
								inputProps={{ maxLength: 250 }}
							/>
						</div>
						<div className={classes.item}>
							<TextInput
								label={t(keys.ACCOUNT.PAYMENT_METHODS.FORM.PHONE_NUMBER)}
								name="phone_number"
								required={true}
								pattern="[0-9+ ]{9,40}"
								value={paymentMethod.phone_number || ""}
								inputProps={{ maxLength: 60 }}
							/>
						</div>
					</div>
				</div>
				<div className={classes.footer}>
					<Button type="submit" loading={status === "loading"}>
						{t(keys.ACCOUNT.PAYMENT_METHODS.FORM.SAVE)}
					</Button>
					<Button color="outlined" onClick={handleOnCancel}>
						{t(keys.ACCOUNT.PAYMENT_METHODS.FORM.CANCEL)}
					</Button>
				</div>
			</div>
		</form>
	);
};

PaymentMethodsForm.defaultProps = {
	classes,
};

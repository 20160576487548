import React from 'react';
import { Skeleton as SkeletonMui } from "@material-ui/lab";


export const Skeleton = ({variant, width, children}) => {
    return (
        <SkeletonMui animation="wave" variant={variant} width={width}>
            {children}
        </SkeletonMui>
    )
}

Skeleton.defaultProps = {
    variant:"",
    width:"",
    children:"",
}
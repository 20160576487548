import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { constants } from "../locales/constant";

export const getOrder = createAsyncThunk("cart/getOrder", async (_, { rejectWithValue, getState }) => {
	try {
		const {
			auth: { access_token },
		} = getState();

		const header = new Headers();
		header.append("Authorization", "Bearer " + access_token);
		header.append("Content-Type", "application/json ");

		const response = await fetch(`${constants.API_SERVER}/order/`, {
			credentials: "include",
			headers: header,
		});

		if (!response.ok) {
			throw new Error("fetching orders failed");
		}

		return await response.json();
	} catch (err) {
		return rejectWithValue(err.message);
	}
});

export const copyOrder = createAsyncThunk("cart/copyOrder", async (id, { rejectWithValue, getState }) => {
	try {
		const {
			auth: { access_token },
		} = getState();
	
		const header = new Headers();
		header.append("Authorization", "Bearer " + access_token);
		header.append("Content-Type", "application/json ");
	
		const response = await fetch(`${constants.API_SERVER}/copyorder/${id}/`, {
			method: "POST",
			credentials: "include",
			headers: header,
		});

		if (!response.ok) {
			throw new Error("copying order failed");
		}

		return await response.json();
	} catch (error) {
		return rejectWithValue(error.message);
	}
});

export const copyLine = createAsyncThunk("cart/copyLine", async (id, { rejectWithValue, getState }) => {
	try {
		const {
			auth: { access_token },
		} = getState();
	
		const header = new Headers();
		header.append("Authorization", "Bearer " + access_token);
		header.append("Content-Type", "application/json ");
	
		const response = await fetch(`${constants.API_SERVER}/copyline/${id}/`, {
			method: "POST",
			credentials: "include",
			headers: header,
		});

		if (!response.ok) {
			throw new Error("copying order line failed");
		}

		return await response.json();
	} catch (error) {
		return rejectWithValue(error.message);
	}
});

export const updateOrder = createAsyncThunk("cart/updateOrder", async (payload, { rejectWithValue, getState }) => {
	try {
		const {
			auth: { access_token },
		} = getState();

		const {
			cart: { order },
		} = getState();

		if (!order?.id) {
			throw new Error(JSON.stringify({errorMsg: "updating order line failed"}));
		}

		const header = new Headers();
		header.append("Authorization", "Bearer " + access_token);
		header.append("Content-Type", "application/json ");

		const response = await fetch(`${constants.API_SERVER}/order/${order.id}/`, {
			method: "PUT",
			credentials: "include",
			headers: header,
			body: JSON.stringify(payload),
		});

		if (!response.ok) {
			const errors = await response.json();
			throw new Error(errors.non_field_errors[0]);
		}

		return await response.json();
	} catch (error) {
		return rejectWithValue(error.message);
	}
});

export const addLine = createAsyncThunk(
	"cart/addLine",
	async ({ variant_id, qty, qty_pack, unitAmount, amount }, { rejectWithValue, getState }) => {
		try {
			const {
				auth: { access_token },
			} = getState();

			const header = new Headers();
			header.append("Authorization", "Bearer " + access_token);
			header.append("Content-Type", "application/json ");

			const response = await fetch(`${constants.API_SERVER}/orderLine/`, {
				method: "POST",
				credentials: "include",
				headers: header,
				body: JSON.stringify({
					variant: variant_id,
					qty: qty,
					qty_pack: qty_pack,
					unitAmount: unitAmount,
					amount: amount,
				}),
			});

			if (!response.ok) {
				throw new Error("adding order line failed");
			}

			return await response.json();
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const updateLine = createAsyncThunk(
	"cart/updateLine",
	async ({ id, variant_id, qty, qty_pack, unitAmount, amount }, { rejectWithValue, getState }) => {
		try {
			const {
				auth: { access_token },
			} = getState();

			const header = new Headers();
			header.append("Authorization", "Bearer " + access_token);
			header.append("Content-Type", "application/json ");
			
			const response = await fetch(`${constants.API_SERVER}/orderLine/${id}/`, {
				method: "PUT",
				credentials: "include",
				headers: header,
				body: JSON.stringify({
					variant: variant_id,
					qty: qty,
					qty_pack: qty_pack,
					unitAmount: unitAmount,
					amount: amount,
				}),
			});

			if (!response.ok) {
				throw new Error("updating order line failed");
			}

			return await response.json();
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const deleteLine = createAsyncThunk("cart/deleteLine", async (id, { rejectWithValue, getState }) => {
	try {
		const {
			auth: { access_token },
		} = getState();
	
		const header = new Headers();
		header.append("Authorization", "Bearer " + access_token);
		header.append("Content-Type", "application/json ");
	
		const response = await fetch(`${constants.API_SERVER}/orderLine/${id}/`, {
			method: "DELETE",
			credentials: "include",
			headers: header,
		});

		if (!response.ok) {
			throw new Error("deleting order line failed");
		}

		return id;
	} catch (error) {
		return rejectWithValue(error.message);
	}
});

export const b2bOrder = createAsyncThunk("cart/b2bOrder", async (_, { rejectWithValue, getState }) => {
	try {
		const {
			auth: { access_token },
		} = getState();

		const header = new Headers();
		if (access_token) {
			header.append("Authorization", "Bearer " + access_token);
		}

		const response = await fetch(`${constants.API_SERVER}/orderSource/`, {
			credentials: "include",
			headers: header,
		});
		if (!response.ok) {
			throw new Error("fetching orders failed");
		}
		return await response.json();
	} catch (err) {
		return rejectWithValue(err.message, err);
	}
});

export const getShippingFees = createAsyncThunk("cart/getShippingFees", async (_, { rejectWithValue, getState }) => {
	return new Promise((resolve) => {
		setTimeout(() => {
			resolve([]);
		}, 2000);
	});
});

const orderLineAdapter = createEntityAdapter({});
const shippingAddressAdapter = createEntityAdapter({
	selectId: (shippingFee) => shippingFee.address_id,
});

const cart = createSlice({
	name: "cart",
	initialState: {
		currentRequestId: undefined,
		currentRequestIdB2BSource: undefined,
		currentRequestIdShippingFees: undefined,
		error: undefined,
		status: undefined,
		statusUpdateOrder: undefined,
		order: undefined,
		orderline: orderLineAdapter.getInitialState(),
		cartOpen: false,
		dataChanged: false,
		isB2BOrder: false,
		shippingAdresses: shippingAddressAdapter.getInitialState(),
	},
	reducers: {
		setCartOpen: (state, { payload }) => {
			state.cartOpen = payload;
			if (payload === false) state.dataChanged = false;
		},
		clear: (state, _) => {
			state.currentRequestId = undefined;
			state.currentRequestIdB2BSource = undefined;
			state.currentRequestIdShippingFees = undefined;
			state.error = undefined;
			state.status = undefined;
			state.statusUpdateOrder = undefined;
			state.order = undefined;
			state.cartOpen = false;
			state.dataChanged = false;
			state.isB2BOrder = false;
			
			orderLineAdapter.removeAll(state.orderline);
			shippingAddressAdapter.removeAll(state.shippingAdresses);
		},
	},
	extraReducers: {
		[getOrder.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestId) {
				state.currentRequestId = undefined;
				state.status = "succeeded";
				state.error = undefined;

				if (payload?.orderline?.length > 0) {
					const { orderline, ...order } = payload;
					state.order = { ...order };
					orderLineAdapter.setAll(state.orderline, orderline);
				}
			}
			return state;
		},
		[getOrder.pending]: (state, { meta }) => {
			state.currentRequestId = meta.requestId;
			state.status = "loading";
			state.error = undefined;

			return state;
		},
		[getOrder.rejected]: (state, { meta, payload }) => {
			if (state.status === "loading" && state.currentRequestId === meta.requestId) {
				state.currentRequestId = undefined;
				state.status = "error";
				state.error = payload;
			}
			return state;
		},
		[copyOrder.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestId) {
				state.currentRequestId = undefined;
				state.status = "succeeded";
				state.error = undefined;
				state.cartOpen = true;
				state.dataChanged = true;

				const { orderline, ...order } = payload;

				if (payload?.orderline?.length > 0) {
					if (state.order === undefined)
						state.order = { ...order };

					orderLineAdapter.removeAll(state.orderline);
					orderLineAdapter.setAll(state.orderline, orderline);
				}
			}
			return state;
		},
		[copyOrder.pending]: (state, { meta }) => {
			state.currentRequestId = meta.requestId;
			state.status = "loading";
			state.error = undefined;

			return state;
		},
		[copyOrder.rejected]: (state, { meta, payload }) => {
			if (state.status === "loading" && state.currentRequestId === meta.requestId) {
				state.currentRequestId = undefined;
				state.status = "error";
				state.error = payload;
			}
			return state;
		},
		[copyLine.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestId) {
				state.currentRequestId = undefined;
				state.status = "succeeded";
				state.error = undefined;
				state.cartOpen = true;
				state.dataChanged = true;

				const { order, ...orderline } = payload;

				if (state.order === undefined)
					state.order = { ...order };
				
				if (state.orderline.ids.includes(orderline.id)){
					orderLineAdapter.updateOne(state.orderline, {
						id: orderline.id,
						changes: {
							qty: orderline.qty,
							qty_pack: orderline.qty_pack,
							unitAmount: orderline.unitAmount,
							discAmount: orderline.discAmount,
							amount: orderline.amount,
						},
					});
				} else {
					orderLineAdapter.addOne(state.orderline, { ...orderline });
				}
			}
			return state;
		},
		[copyLine.pending]: (state, { meta }) => {
			state.currentRequestId = meta.requestId;
			state.status = "loading";
			state.error = undefined;

			return state;
		},
		[copyLine.rejected]: (state, { meta, payload }) => {
			if (state.status === "loading" && state.currentRequestId === meta.requestId) {
				state.currentRequestId = undefined;
				state.status = "error";
				state.error = payload;
			}
			return state;
		},
		[updateOrder.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestId) {
				state.currentRequestId = undefined;
				state.statusUpdateOrder = "succeeded";
				state.error = undefined;

				if (payload?.orderline?.length > 0) {
					const { orderline, ...order } = payload;
					state.order = { ...order };
					orderLineAdapter.setAll(state.orderline, orderline);
				}
			}
			return state;
		},
		[updateOrder.pending]: (state, { meta }) => {
			state.currentRequestId = meta.requestId;
			state.statusUpdateOrder = "loading";
			state.error = undefined;

			return state;
		},
		[updateOrder.rejected]: (state, { meta, payload }) => {
			if (state.statusUpdateOrder === "loading" && state.currentRequestId === meta.requestId) {
				state.currentRequestId = undefined;
				state.statusUpdateOrder = "error";
				state.error = payload;
			}
			return state;
		},
		[addLine.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestId) {
				state.currentRequestId = undefined;
				state.status = "succeeded";
				state.error = undefined;
				state.cartOpen = true;
				state.dataChanged = true;

				const { order, ...orderline } = payload;
				
				if (state.order === undefined)
					state.order = { ...order };

				if (state.orderline.ids.includes(orderline.id)){
					orderLineAdapter.updateOne(state.orderline, {
						id: orderline.id,
						changes: {
							qty: orderline.qty,
							qty_pack: orderline.qty_pack,
							unitAmount: orderline.unitAmount,
							discAmount: orderline.discAmount,
							amount: orderline.amount,
						},
					});
				} else {
					orderLineAdapter.addOne(state.orderline, { ...orderline });
				}
			}

			return state;
		},
		[addLine.pending]: (state, { meta }) => {
			state.currentRequestId = meta.requestId;
			state.status = "loading";
			state.error = undefined;

			return state;
		},
		[addLine.rejected]: (state, { meta, payload }) => {
			if (state.status === "loading" && state.currentRequestId === meta.requestId) {
				state.currentRequestId = undefined;
				state.status = "error";
				state.error = payload;
			}
			return state;
		},
		[updateLine.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestId) {
				state.currentRequestId = undefined;
				state.status = "succeeded";
				state.error = undefined;
				state.cartOpen = meta.arg.cartOpen;
				state.dataChanged = true;

				const { id, qty, qty_pack, unitAmount, discAmount, amount } = payload;
				orderLineAdapter.updateOne(state.orderline, {
					id: id,
					changes: {
						qty,
						qty_pack,
						unitAmount,
						discAmount,
						amount,
					},
				});
			}

			return state;
		},
		[updateLine.pending]: (state, { meta }) => {
			state.currentRequestId = meta.requestId;
			state.status = "loading";
			state.error = undefined;

			return state;
		},
		[updateLine.rejected]: (state, { meta, payload }) => {
			if (state.status === "loading" && state.currentRequestId === meta.requestId) {
				state.currentRequestId = undefined;
				state.status = "error";
				state.error = payload;
			}
			return state;
		},
		[deleteLine.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestId) {
				state.currentRequestId = undefined;
				state.status = "succeeded";
				state.error = undefined;

				orderLineAdapter.removeOne(state.orderline, payload);
				const remainingItems = state.orderline.ids.length;
				if (remainingItems === 0) {
					state.order = undefined;
				}
			}
		},
		[deleteLine.pending]: (state, { meta }) => {
			state.currentRequestId = meta.requestId;
			state.status = "loading";
			state.error = undefined;

			return state;
		},
		[deleteLine.rejected]: (state, { meta, payload }) => {
			if (state.status === "loading" && state.currentRequestId === meta.requestId) {
				state.currentRequestId = undefined;
				state.status = "error";
				state.error = payload;
			}
			return state;
		},
		[b2bOrder.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestIdB2BSource) {
				state.currentRequestIdB2BSource = undefined;
				state.status = "succeeded";
				state.error = undefined;
				state.isB2BOrder = payload.has_B2B_orders;
			}
			return state;
		},
		[b2bOrder.pending]: (state, { meta }) => {
			state.currentRequestIdB2BSource = meta.requestId;
			state.status = "loading";
			state.error = undefined;

			return state;
		},
		[b2bOrder.rejected]: (state, { meta, payload }) => {
			if (state.status === "loading" && state.currentRequestIdB2BSource === meta.requestId) {
				state.currentRequestIdB2BSource = undefined;
				state.status = "error";
				state.error = payload;
			}
			return state;
		},
		[getShippingFees.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestIdShippingFees) {
				state.currentRequestIdShippingFees = undefined;
				state.status = "succeeded";
				state.error = undefined;

				shippingAddressAdapter.setAll(state.shippingAdresses, payload);
			}
			return state;
		},
		[getShippingFees.pending]: (state, { meta }) => {
			state.currentRequestIdShippingFees = meta.requestId;
			state.status = "loading";
			state.error = undefined;

			return state;
		},
		[getShippingFees.rejected]: (state, { meta, payload }) => {
			if (state.status === "loading" && state.currentRequestIdShippingFees === meta.requestId) {
				state.currentRequestIdShippingFees = undefined;
				state.status = "error";
				state.error = payload;
			}
			return state;
		},
	},
});

export const { setCartOpen, clear } = cart.actions;
export default cart;

const orderLineSelectors = orderLineAdapter.getSelectors((state) => state.cart.orderline);
const shippingFeesSelectors = shippingAddressAdapter.getSelectors((state) => state.cart.shippingAdresses);

export const orderLinesSelector = createSelector(orderLineSelectors.selectAll, (orderLines) => orderLines);
export const cartHasItemsSelector = createSelector(orderLineSelectors.selectAll, (orderLines) => orderLines.length > 0);

export const orderSelector = (state) => state.cart.order || {};
export const isLoadingSelector = (state) => state.cart.status === "loading";
export const errorSelector = (state) => state.cart.error;
export const cartOpenSelector = (state) => state.cart.cartOpen;
export const cartDataChangedSelector = (state) => state.cart.dataChanged;

export const isB2BOrderSelector = (state) => state.cart.isB2BOrder;

export const shippingFeesSelector = createSelector(shippingFeesSelectors.selectAll, (shippingFees) => shippingFees);

import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { constants } from "../locales/constant";

export const addShippingAddress = createAsyncThunk(
	"shippingAddress/addShippingAddress",
    async (address, { rejectWithValue, getState }) => {
		try {
			const {
				auth: { access_token },
			} = getState();

			const header = new Headers();
			header.append("Authorization", "Bearer " + access_token);
			header.append("Content-Type", "application/json");
			const response = await fetch(`${constants.API_SERVER}/address/`, {
				method: "POST",
				headers: header,
				body: JSON.stringify(address),
			});
			if (!response.ok) {
				const errors = await response.json();
				throw new Error(errors.non_field_errors[0]);
			}
			return await response.json();
		} catch (err) {
			return rejectWithValue(err.message, err);
		}
	}
);

export const updateShippingAddress = createAsyncThunk(
	"shippingAddress/updateShippingAddress",
    async (address, { rejectWithValue, getState }) => {
		try {
			const {
				auth: { access_token },
			} = getState();

			const header = new Headers();
			header.append("Authorization", "Bearer " + access_token);
			header.append("Content-Type", "application/json");
			const response = await fetch(`${constants.API_SERVER}/address/${address.id}/`, {
				method: "PUT",
				headers: header,
				body: JSON.stringify(address),
			});
			if (!response.ok) {
				const errors = await response.json();
				throw new Error(errors.non_field_errors[0]);
			}
			return await response.json();
		} catch (err) {
			return rejectWithValue(err.message, err);
		}
	}
);

export const deleteShippingAddress = createAsyncThunk(
	"shippingAddress/deleteShippingAddress",
	async (shippingAddressId, { rejectWithValue, getState }) => {
		try {
			const {
				auth: { access_token },
			} = getState();

			const header = new Headers();
			header.append("Authorization", "Bearer " + access_token);
			header.append("Content-Type", "application/json");
			const response = await fetch(constants.API_SERVER + `/address/${shippingAddressId}/`, {
				method: "DELETE",
				credentials: "include",
				headers: header,
			});
			if (!response.ok) {
				throw new Error("deleting address failed");
			}
			return shippingAddressId;
		} catch (err) {
			return rejectWithValue(err.message, err);
		}
	}
);

const shippingAddressesAdapter = createEntityAdapter({});

const shippingAddresses = createSlice({
	name: "shippingAddresses",
	initialState: shippingAddressesAdapter.getInitialState({
		currentRequestId: undefined,
		error: undefined,
		status: undefined,
		shippingAddressId: null,
	}),
	reducers: {
		resetStatus(state, _) {
			state.status = undefined;
			state.error = undefined;
			state.shippingAddressId = null;
		},
		setShippingAddress(state, { payload }) {
			state.shippingAddressId = payload;
		},
		setShippingAddresses(state, { payload }) {
			shippingAddressesAdapter.setAll(state, payload || []);
		},
	},
	extraReducers: {
		[addShippingAddress.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestId) {
				state.currentRequestId = undefined;
				state.status = "succeeded";
				state.error = undefined;

				shippingAddressesAdapter.addOne(state, payload);
			}
			return state;
		},
		[addShippingAddress.pending]: (state, { meta }) => {
			state.currentRequestId = meta.requestId;
			state.status = "loading";
			state.error = undefined;

			return state;
		},
		[addShippingAddress.rejected]: (state, { meta, payload }) => {
			if (state.status === "loading" && state.currentRequestId === meta.requestId) {
				state.currentRequestId = undefined;
				state.status = "error";
				state.error = payload;
			}
			return state;
		},
		[updateShippingAddress.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestId) {
				state.currentRequestId = undefined;
				state.status = "succeeded";
				state.error = undefined;

				shippingAddressesAdapter.updateOne(state, { id: payload.id, changes: { ...payload } });
			}
			return state;
		},
		[updateShippingAddress.pending]: (state, { meta }) => {
			state.currentRequestId = meta.requestId;
			state.status = "loading";
			state.error = undefined;

			return state;
		},
		[updateShippingAddress.rejected]: (state, { meta, payload }) => {
			if (state.status.updateShippingAddress === "loading" && state.currentRequestId === meta.requestId) {
				state.currentRequestId = undefined;
				state.status = "error";
				state.error = payload;
			}
			return state;
		},
		[deleteShippingAddress.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestId) {
				state.currentRequestId = undefined;
				state.status = "succeeded";
				state.error = undefined;
				shippingAddressesAdapter.removeOne(state, payload);
			}
			return state;
		},
		[deleteShippingAddress.pending]: (state, { meta }) => {
			state.currentRequestId = meta.requestId;
			state.status = "loading";
			state.error = undefined;

			return state;
		},
		[deleteShippingAddress.rejected]: (state, { meta, payload }) => {
			if (state.status === "loading" && state.currentRequestId === meta.requestId) {
				state.currentRequestId = undefined;
				state.status = "error";
				state.error = payload;
			}
			return state;
		},
	},
});

export const { resetStatus, setShippingAddress, setShippingAddresses } = shippingAddresses.actions;
export default shippingAddresses;

const shippingAddressSelectors = shippingAddressesAdapter.getSelectors((state) => state.shippingAddresses);

export const shippingAddressesSelector = createSelector(
	shippingAddressSelectors.selectAll,
	(shippingAddress) => shippingAddress
);

export const shippingAddressSelector = createSelector(
    [(state) => state.shippingAddresses.shippingAddressId, shippingAddressSelectors.selectEntities],
    (selectedId, entities) => {
        if (!selectedId) return {};

        const shippingAddress = entities[selectedId];

        if (!shippingAddress) return {};

        const country = constants.COUNTRIES.find((c) => c.id === shippingAddress.country);
        const province = country?.states.find((p) => p.id === shippingAddress.state);

        return {
            ...shippingAddress,
            country,
            state: province,
        };
    }
);

export const errorSelector = (state) => state.shippingAddresses.error;
import { useTranslation } from "react-i18next";

import { constants } from "../locales/constant";
import { keys } from "../locales/localeskeys";

export const useOrderLine = (t, lngId) => {
	const getImageURL = (overview_image, variant, specification, format, brand) => {
		let docs = overview_image || [];

		let overviewImages = docs.filter((image) => image.type === 3);
		if (overviewImages.length > 0) docs = overviewImages;

		if (variant) {
			if (specification?.id) {
				overviewImages = docs.filter((doc) => doc.specification?.id === specification.id);
				if (overviewImages.length > 0) docs = overviewImages;
			}

			if (format?.id) {
				overviewImages = docs.filter((doc) => doc.format?.id === format.id);
				if (overviewImages.length > 0) docs = overviewImages;
			}

			if (brand?.id) {
				overviewImages = docs.filter((doc) => doc.brand?.id === brand.id);
				if (overviewImages.length > 0) docs = overviewImages;
			}
		}

		return overviewImages.length > 0 ? overviewImages[0].url : constants.DEFAULT_IMG;
	};

	const formatCurrency = (amount) => {
		const amountStr = Number(amount)
			.toFixed(2)
			.toString()
			.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		return lngId.startsWith("fr") ? `${amountStr.replace(".", ",")}$` : `$${amountStr}`;
	};

	const getPackagingLabel = (packagingType, isPlural) => {
		const packagingLabels = {
			1: { singular: t(keys.GLOBAL.COMMON.BAG), plural: t(keys.GLOBAL.COMMON.BAGS) },
			2: { singular: t(keys.GLOBAL.COMMON.BARREL), plural: t(keys.GLOBAL.COMMON.BARRELS) },
			3: { singular: t(keys.GLOBAL.COMMON.BOX), plural: t(keys.GLOBAL.COMMON.BOXES) },
			4: { singular: t(keys.GLOBAL.COMMON.EACH), plural: t(keys.GLOBAL.COMMON.EACHS) },
			5: { singular: t(keys.GLOBAL.COMMON.CANE), plural: t(keys.GLOBAL.COMMON.CANES) },
			6: { singular: t(keys.GLOBAL.COMMON.DRUM), plural: t(keys.GLOBAL.COMMON.DRUMS) },
			7: { singular: t(keys.GLOBAL.COMMON.PAIL), plural: t(keys.GLOBAL.COMMON.PAILS) },
			8: { singular: t(keys.GLOBAL.COMMON.TOTE), plural: t(keys.GLOBAL.COMMON.TOTES) },
		};
	
		return packagingLabels[packagingType] 
			? (isPlural ? packagingLabels[packagingType].plural : packagingLabels[packagingType].singular) 
			: null;
	};

	const formatPackaging = (format, variant, forceSingular = false) => {
		let inStock = 0;
		let packagingFormat = undefined;
		let displayFormat = false;

        if (variant?.on_hand[0]?.qty > 0 && variant?.on_hand[0]?.multiple > 0) {
            inStock = Number(variant?.on_hand[0]?.qty) / Number(variant?.on_hand[0]?.multiple);
        }

		const isPlural = !forceSingular && inStock > 1;
		packagingFormat = getPackagingLabel(format?.packaging, isPlural);
		displayFormat = packagingFormat && format?.packaging === 3

		if (!packagingFormat) {
			packagingFormat = getPackagingLabel(variant?.on_hand?.multiple > 1 ? 3 : 4, isPlural);
			displayFormat = true;
		}

		return { inStock: inStock.toFixed(0), packagingFormat, displayFormat };
	};

	const processedOrderLine = (line) => {
		const { id, amount, qty, unitAmount, item, variant } = line;
		const { specification, format, brand } = variant || {};
		const label = `${item?.erp_code || ""} ${brand?.erp_code || ""}`;
		const image = getImageURL(line.overview_image, variant, specification, format, brand);
        const packaging = variant?.on_hand[0]?.multiple

		return { id, amount, qty, unitAmount, item, variant, specification, format, brand, label, image, packaging };
	};

	return { processedOrderLine, formatCurrency, formatPackaging };
};

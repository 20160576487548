import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
	isCreditCardEnabled,
	isShippingFeesEnabled,
	isLoggedInSelector,
	setPrecedentUrl,
	taxPercentSelector,
} from "../../store/auth";
import { orderLinesSelector, orderSelector } from "../../store/cart";

import { keys } from "../../locales/localeskeys";

import { CheckoutProvider } from "../../providers";

import { HeaderPage } from "../../commons";
import { OrderLines, Summary } from "./sections";

import classes from "./Cart.module.scss";

const Cart = ({ classes }) => {
	const dispatch = useDispatch();

	const { t, i18n } = useTranslation();
	const lngId = i18n.language;

	const isLoggedIn = useSelector(isLoggedInSelector);
	const order = useSelector(orderSelector);
	const orderLines = useSelector(orderLinesSelector);
	const isAvailableCreditCard = useSelector(isCreditCardEnabled);
	const isAvailableShippingFees = useSelector(isShippingFeesEnabled);
	const taxPercent = useSelector(taxPercentSelector);

    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

	useEffect(() => {
		if (!isLoggedIn) {
			dispatch(setPrecedentUrl({ pathname: location.pathname, search: location.search }));
			navigate({ pathname: `/sign-in`, search: queryParams.toString() });
		} else {
			if (!order.id) navigate({ pathname: `/`, search: queryParams.toString() });
		}
	}, [isLoggedIn]);

	return (
		<section className="pages-layout">
			<HeaderPage
				tree={[
					{ label: t(keys.GLOBAL.NAVBAR.HOME), to: `/?lng=${lngId}` },
					{ label: t(keys.CART.OVERVIEW.TITLE), to: `/cart?lng=${lngId}` },
				]}
				title={t(keys.CART.OVERVIEW.TITLE)}
			/>

			<main>
                <article className={classes.checkout}>
					<CheckoutProvider
						classes={classes}
						order={order}
						isAvailableCreditCard={isAvailableCreditCard}
						isAvailableShippingFees={isAvailableShippingFees}
						orderLines={orderLines}
						taxPercent={taxPercent}
					>
                        <div className={classes.cards}>
                            <OrderLines />
                        </div>
                        <div className={classes.cards}>
                            <Summary />
                        </div>
                    </CheckoutProvider>
				</article>
			</main>
		</section>
	);
};

Cart.defaultProps = {
	classes,
};

export default Cart;

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { itemCartsSelector, itemIsLoadingSelector, productCountSelector } from "../../../../store/product";
import { customerFavoriteBrandSelector } from "../../../../store/auth";
import { favoriteSelector, pageSelector, setPage } from "../../../../store/productQuery";

import Pagination from "../../../ui/Pagination/Pagination";
import ProductCard from "./ProductCard/ProductCard";
import Loading from "../../../ui/loading/Loading";
import { useLocalized } from "../../../../hooks";

import { constants as constant } from "../../../../locales/constant";
import classes from "./ProductGrid.module.scss";

const ProductGrid = (props) => {
	const dispatch = useDispatch();

	const { i18n } = useTranslation();
	const lngId = i18n.language;

	const { getLocalizedValueByKeyType, getLocalizedValueByCustomMapping } = useLocalized(lngId);

	const navigate = useNavigate();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const pageQuery = queryParams.get("page");

	const currentPage = useSelector(pageSelector);
	const productStore = useSelector(productCountSelector);

	const items = useSelector(itemCartsSelector);
	const itemsLoading = useSelector(itemIsLoadingSelector);
	const filterOnlyFavorite = useSelector(favoriteSelector);
	const favoriteBrands = useSelector(customerFavoriteBrandSelector);

	const layoutRef = useRef();

	const [itemsToRender, setItemsToRender] = useState(items);

	useEffect(() => {
		dispatch(setPage(parseInt(pageQuery)));
	}, [pageQuery]);

	const changePageHandler = (page) => {
		queryParams.set("page", page);
		navigate({
			pathname: location.pathname,
			search: queryParams.toString(),
		});

		window.scrollTo(0, 0);
	};

	useEffect(() => {
		if (filterOnlyFavorite && favoriteBrands) {
			setItemsToRender(
				items.filter(
					(item) => favoriteBrands.includes(item.brandId) || (favoriteBrands.includes(null) && !item.brandId)
				)
			);
		} else setItemsToRender(items);
	}, [filterOnlyFavorite, favoriteBrands, items]);

	useEffect(() => {
		if (layoutRef.current) {
			props.setPageSize(layoutRef.current.offsetWidth >= 961 && layoutRef.current.offsetWidth <= 1249 ? 12 : 16);
		}
	}, [layoutRef.current, layoutRef.current?.offsetWidth]);
	return (
		<div className={classes.sectionContainer} ref={layoutRef}>
			{itemsLoading && <Loading type={"bounce"} />}
			{!itemsLoading && (
				<div className={classes.gridContainer}>
					{itemsToRender.map((item) => (
						<ProductCard
							id={item.id}
							itemId={item.itemId}
							itemErpCode={item.erp_code}
							brandId={item.brandId}
							brandErpCode={item.brandErpCode}
							subBrandCode={item.subBrandCode}
							subBrandCode_fr={item.subBrandCode_fr}
							key={item.id}
							defaultImage={item.defaultImage ? item.defaultImage : constant.DEFAULT_IMG}
							title={item.title}
							description={getLocalizedValueByKeyType(item, "description")}
							ProductHeaderLabel={getLocalizedValueByKeyType(item, "label")}
							formats={getLocalizedValueByCustomMapping(item, { en_us: "formats_en_us", default: "formats" })}
							searched_specification={item.searched_specification}
							isWMH={item.is_wmh}
						/>
					))}
				</div>
			)}
			{!itemsLoading && (
				<Pagination
					className={classes.pagination}
					currentPage={currentPage}
					totalCount={productStore}
					pageSize={props.pageSize}
					onPageChange={(page) => changePageHandler(page)}
				/>
			)}
		</div>
	);
};

export default ProductGrid;

import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { keys } from "../../../../locales/localeskeys";
import { constants } from "../../../../locales/constant";

import { customerIdSelector } from "../../../../store/auth";
import {
	addShippingAddress,
	errorSelector,
	resetStatus,
	shippingAddressSelector,
	updateShippingAddress,
} from "../../../../store/shippingAddress";

import { useFormActions } from "../../../../providers";
import { useLocalized } from "../../../../hooks";

import { Button, Alert, SearchSelectDropdown, TextInput } from "../../../../atoms";

import classes from "./ShippingAddressForm.module.scss";

export const ShippingAddressForm = ({ classes }) => {
	const dispatch = useDispatch();

	const { t, i18n } = useTranslation();
	const { getLocalizedValueByCustomMapping } = useLocalized(i18n.language);

	const status = useSelector((state) => state.shippingAddresses.status);
	const shippingAddress = useSelector(shippingAddressSelector);
	const customerId = useSelector(customerIdSelector);
	const error = useSelector(errorSelector);

	const navigate = useNavigate();

	const [country, setCountry] = useState(shippingAddress?.country || {});
	const [state, setState] = useState(shippingAddress?.state || {});
	const [states, setStates] = useState([]);

	const zipCodeRef = useRef();

	const { handleOnCallback } = useFormActions(() => navigate("/account/shipping-addresses"));

	const handleUppercaseChange = (e) => {
		if (zipCodeRef.current) {
			zipCodeRef.current.value = e.target.value.toUpperCase();
		}
	};

	const handleCountry = (value) => {
		setCountry(value);
		setState({});
	};

	const handleOnSave = (e) => {
		e.preventDefault();

		if (!shippingAddress.id) {
			dispatch(
				addShippingAddress({
					type: 0,
					line1: e.target.elements.line1.value,
					line2: e.target.elements.line2.value,
					city: e.target.elements.city.value,
					postal_code: e.target.elements.postal_code.value,
					state: state.id,
					country: country.id,
					name: e.target.elements.name.value,
					phone: e.target.elements.phone.value,
					is_default: false,
					customer: customerId,
				})
			);
		} else {
			dispatch(
				updateShippingAddress({
					id: shippingAddress.id,
					line1: e.target.elements.line1.value,
					line2: e.target.elements.line2.value,
					city: e.target.elements.city.value,
					postal_code: e.target.elements.postal_code.value,
					state: state.id,
					country: country.id,
					name: e.target.elements.name.value,
					phone: e.target.elements.phone.value,
					is_default: shippingAddress.is_default,
					customer: customerId,
				})
			);
		}
	};

	const handleOnCancel = () => {
		dispatch(resetStatus());
		handleOnCallback();
	};

	useEffect(() => {
		if (country?.states) {
			setStates(country.states);
		}
	}, [country]);

	useEffect(() => {
		if (status === "succeeded") {
			dispatch(resetStatus());
			handleOnCallback();
		}
	}, [status]);

	return (
		<form onSubmit={handleOnSave}>
			<div className={classes.frameset}>
				{error && <Alert severity="error">{t(keys.ACCOUNT.ADDRESS.ERRORS[error])}</Alert>}
				<div className={classes.section}>
					<div className={classes.article}>
						<div className={classes.item}>
							<TextInput
								label={t(keys.ACCOUNT.ADDRESS.FORM.COMPANY_NAME)}
								name="name"
								value={shippingAddress.name || ""}
								required={true}
								inputProps={{ maxLength: 60 }}
							/>
						</div>
					</div>
					<div className={classes.article}>
						<div className={classes.item}>
							<TextInput
								label={t(keys.ACCOUNT.ADDRESS.FORM.STREET_ADDRESS)}
								name="line1"
								value={shippingAddress.line1 || ""}
								required={true}
								inputProps={{ maxLength: 250 }}
							/>
						</div>
						<div className={classes.item}>
							<TextInput
								label={t(keys.ACCOUNT.ADDRESS.FORM.ZIP_CODE)}
								name="postal_code"
								value={shippingAddress.postal_code || ""}
								required={true}
								pattern="(\d{5}(-?\d{4})?|[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d)"
								onChange={handleUppercaseChange}
								ref={zipCodeRef}
								inputProps={{ maxLength: 10 }}
								caption={getLocalizedValueByCustomMapping(
									{ example_us: "ex.: 12345", example_ca: "ex.: A1A1A1" },
									{ en_us: "example_us", default: "example_ca" }
								)}
							/>
						</div>
					</div>
					<div className={classes.article}>
						<div className={classes.item}>
							<SearchSelectDropdown
								options={constants.COUNTRIES}
								optionLabel="name"
								required={true}
								label={t(keys.ACCOUNT.ADDRESS.FORM.COUNTRY)}
								name="country"
								value={country}
								onChange={handleCountry}
							/>
						</div>
						<div className={classes.item}>
							<SearchSelectDropdown
								options={states}
								optionLabel="name"
								required={true}
								label={t(keys.ACCOUNT.ADDRESS.FORM.STATE)}
								name="state"
								value={state}
								onChange={(value) => setState(value)}
							/>
						</div>
						<div className={classes.item}>
							<TextInput
								label={t(keys.ACCOUNT.ADDRESS.FORM.CITY)}
								name="city"
								value={shippingAddress.city || ""}
								required={true}
								inputProps={{ maxLength: 60 }}
							/>
						</div>
					</div>
					<div className={classes.article}>
						<div className={classes.item}>
							<TextInput
								label={t(keys.ACCOUNT.ADDRESS.FORM.UNIT)}
								name="line2"
								value={shippingAddress.line2 || ""}
								inputProps={{ maxLength: 250 }}
							/>
						</div>
						<div className={classes.item}>
							<TextInput
								label={t(keys.ACCOUNT.ADDRESS.FORM.PHONE_NUMBER)}
								name="phone"
								value={shippingAddress.phone || ""}
								required={true}
								pattern="[0-9+ ]{9,40}"
								inputProps={{ maxLength: 60 }}
							/>
						</div>
					</div>
				</div>
				<div className={classes.footer}>
					<Button type="submit" loading={status === "loading"}>
						{t(keys.ACCOUNT.ADDRESS.FORM.SAVE)}
					</Button>
					<Button color="outlined" onClick={handleOnCancel}>
						{t(keys.ACCOUNT.ADDRESS.FORM.CANCEL)}
					</Button>
				</div>
			</div>
		</form>
	);
};

ShippingAddressForm.defaultProps = {
	classes,
};
